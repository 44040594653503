/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name: Maniva Meetup HTML5 Template
Theme URI: http://www.templaza.com/
Author: templaza
Author URI: http://themeforest.net/user/templaza
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: animation, blog, bootstrap, business, corporate, css3, html5, portfolio, responsive
------------------------------------------------------------------*/
/**

/**
* Table of Contents:
* 1.0 - General
* 2.0 - Header Style
* 3.0 - Slider Home
* 4.0 - Content
*   4.1 - About
*   4.2 - Video Meetup
*   4.3 - Counter
*   4.4 - Text Box Button
*   4.5 - Slider Meetup
*   4.6 - List
*   4.7 - Box text title line
*   4.8 - Our Team meetup
*   4.9 - Tab Event
*   4.10 - Twitter
*   4.11 - Gallery
*   4.12 - Box text title_2
*   4.13 - Speaker client
*   4.14 - FAQ
*   4.15 - Register
*   4.16 - Recent posts
*   4.17 - Contact
*   4.18 - Breadcrumb
* 5.0 - Blog
* 6.0 - Sidebar
* 7.0 - Contact
* 8.0 - typography
* 9.0 - Footer
* 10.0 - Responsive
* -----------------------------------------------------------------------------
*/

/**
 * 1.0 - General
 * -----------------------------------------------------------------------------
 */
body {
    margin: 0;
    padding: 0;
    line-height: 1.85em;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    overflow-x: hidden;
    position: relative;
}
.tz_bd_overflow {
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
.nav > li > a:focus, .nav > li > a:hover {
    background: transparent;
}
a {
    color: #e45914;
    text-decoration: none;
}
a:focus {
    outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
    color: #242732;
    line-height: 1.4em;
    font-weight: 700;
    margin-top: 0;
}
blockquote {
    font-size: 14px;
    line-height: 1.6em;
    padding: 0 20px;
}
.ds-table {
    display: table;
    height: 100%;
    width: 100%;
}
.ds-table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
img {
    height: auto;
    max-width: 100%;
}
button:focus {
    outline: none;
}
.custom_col_pading_left {
    padding-left: 0;
}
.custom_col_pading_right {
    padding-right: 0;
}
ul li,
ol li {
    padding: 5px 0;
}
code {
    background-color: rgba(228, 89, 20, 0.2);
    display: block;
    padding: 20px;
}
embed, iframe, object, video {
    max-width: 100%;
}
.iframe-full-audio {
    height: 350px;
    width: 100%;
}
.iframe-full {
    height: 474px;
    width: 100%;
}

@font-face {
    font-family: 'flexslider-icon';
    src: url('fonts/flexslider-icon.eot');
    src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}
.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}
.flex-direction-nav  li {
    padding: 0;
}
.flexslider {
    padding: 0;
    margin: 0 0 60px;
    background: #fff;
    border: 4px solid #fff;
    position: relative;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    zoom: 1;
}
.flexslider .slides {
    zoom: 1;
}
.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
    width: 100%;
    display: block;
}
.flex-pauseplay span {
    text-transform: capitalize;
}
.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
}
.flex-pauseplay a:before {
    font-family: "flexslider-icon";
    font-size: 20px;
    display: inline-block;
    content: '\f004';
}
.flex-pauseplay a:hover {
    opacity: 1;
}
.flex-pauseplay a.flex-play:before {
    content: '\f003';
}
.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
html[xmlns] .slides {
    display: block;
}
* html .slides {
    height: 1%;
}
/* If you are not using another script, such as Modernizr, make sure you* include js that eliminates this class on page load */
.no-js .slides  > li:first-child {
    display: block;
}
.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.flex-viewport ul li {
    padding: 0;
}
.loading .flex-viewport {
    max-height: 300px;
}
.carousel li {
    margin-right: 5px;
}
.flex-direction-nav {
    *height: 0;
}
.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.flex-direction-nav .flex-prev {
    left: -50px;
}
.flex-direction-nav .flex-next {
    right: -50px;
    text-align: right;
}
.flex-direction-nav .flex-disabled {
    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
}
.flex-direction-nav a:before {
    font-family: 'FontAwesome';
    font-size: 40px;
    line-height: 1;
    display: inline-block;
    content: '\f104';
}
.flex-direction-nav a.flex-next:before {
    content: '\f105';
}
.flexslider:hover .flex-prev {
    opacity: 0.7;
    left: 10px;
}
.flexslider:hover .flex-next {
    opacity: 0.7;
    right: 10px;
}
.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
}
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default;
}
.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}
.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}
.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
}
.flex-control-thumbs img:hover {
    opacity: 1;
}
.flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
}
.eg-adams-container {
    background: #e45914 !important;
}
.eg-henryharrison-container,
.eg-johnson-container {
    background: rgba(232, 15, 96, 0.85) !important;
}
@media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev {
        opacity: 1;
        left: 10px;
    }
    .flex-direction-nav .flex-next {
        opacity: 1;
        right: 10px;
    }
}

/* eq height */
.vc_row-flex {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.vc_row-flex .vc_column-inner {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex-grow: 1;
    width: 100%;
}
.vc_row-flex .vc_column_container {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
}
.vc_empty_space {
    height: 32px;
}
.vc_empty_space_custom {
    height: 100px;
}
.vc_empty_space_custom_2 {
    height: 96px;
}
.vc_empty_space_custom_3 {
    height: 60px;
}
.vc_empty_space_4 {
    height: 66px;
}

/* parallax */
.parallax {
    background-attachment: fixed !important;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}
.overlay_parallax {
    background-color: rgba(9, 9, 31, 0.5);
}

/* easy */
#easy-opener-section {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10001;
}
.easy-opener-bg {
    background: #0d0d0d none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0.97;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.easy-opener-content {
    left: 50%;
    margin-left: -500px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 1000px;
}
.easy-opener-content img {
    max-height: 100%;
    max-width: 100%;
}
.easy-opener-close {
    background: rgba(0, 0, 0, 0) url("images/sprites.png") no-repeat scroll -280px 0;
    display: block;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    position: absolute;
    text-indent: -9999px;
    top: -60px;
    transition: opacity 0.1s ease-in-out 0s;
    width: 30px;
}
.easy-opener-next {
    background: rgba(0, 0, 0, 0) url("images/sprites.png") no-repeat scroll -160px 0;
    display: block;
    height: 30px;
    margin-right: -50px;
    position: absolute;
    right: 50%;
    text-indent: -9999px;
    top: -60px;
    transition: opacity 0.1s ease-in-out 0s;
    width: 30px;
}
.easy-opener-prev {
    background: rgba(0, 0, 0, 0) url("images/sprites.png") no-repeat scroll -190px 0;
    display: block;
    height: 30px;
    left: 50%;
    margin-left: -50px;
    position: absolute;
    text-indent: -9999px;
    top: -60px;
    transition: opacity 0.1s ease-in-out 0s;
    width: 30px;
}
.easy-opener-close:hover,
.easy-opener-next:hover,
.easy-opener-prev:hover {
    opacity: 0.7;
    color: #ffffff;
}
.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
    border: medium none;
}
.tz_check_width_left_position {
    margin: 0 0 0 auto;
}
.tz_check_width_right_position {
    margin: 0 auto 0 0;
}

/**
 * 2.0 - Header Style
 * -----------------------------------------------------------------------------
 */
.tz-headerHome {
    z-index: 52;
}
.tz-header-content {
    position: relative;
}
.tz-headerHome ul li {
    position: relative;
    padding: 0 14px;
}
.tz-headerHome ul li a {
    margin: 0;
    padding: 40px 0;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.4em;
    font-family: 'Raleway', sans-serif;
    display: block;
    text-transform: uppercase;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tzHeaderContainer:after {
    content: '';
    clear: both;
    display: table;
}
.tzHeaderContainer h3 {
    margin: 0;
    padding: 35px 40px 35px 0;
    line-height: normal;
    font-size: inherit;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tzHeaderContainer ul {
    padding: 0;
}
.tz-headerHome {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: fixed;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz-headerHome ul li a:before {
    position: absolute;
    bottom: 35%;
    left: 0;
    content: '';
    background: #e45914;
    height: 3px;
    width: 0;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz-headerHome ul li a:hover:before {
    width: 100%;
}
.tz-headerHome button.tz-search {
    background: none;
    border: none;
    margin: 35px 0 0 25px;
    padding: 0;
    display: block;
    overflow: hidden;
}
.tz-headerHome button.tz-search i.fa {
    color: #242732;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.tz-headerHome .tz-form-search {
    background: #fff none repeat scroll 0 0;
    display: none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.tz-headerHome .tz-form-search .container {
    height: 100%;
}
.tz-headerHome .tz-form-search form {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border: none;
}
.tz-headerHome .tz-form-search .Tzsearchform {
    border: 0 none;
    color: #222;
    font-size: 20px;
    font-weight: 300;
    height: 100%;
    line-height: 30px;
    margin: 0;
    padding: 5px 0;
    width: 100%;
}
.tz-headerHome .tz-form-search .fa.tz-icon-form-search {
    display: none;
}
.tz-headerHome .tz-form-search .fa.tz-form-close {
    color: #e45914;
    cursor: pointer;
    font-size: 18px;
    margin-top: -11px;
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
}
    /* Sub Menu */
    .tz-headerHome nav > ul > li {
        position: relative;
        padding: 0 14px;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu {
        position: absolute;
        top: 120%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        margin: 0;
        padding: 0;
        background: rgba(0, 0, 0, 0.7);
        width: 220px;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        list-style: none;
    }
    .tz-headerHome nav > ul > li:hover ul.non_mega_menu {
        top: 100%;
        opacity: 1;
        visibility: visible;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu > li:first-child {
        border-top: 1px solid #e45914;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu > li {
        list-style: outside none none;
        padding: 0 29px;
        position: relative;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu > li > a {
        border: none;
        padding: 15px 0;
        font-size: 11px;
        font-weight: 400;
        line-height: 11px;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    .tz-headerHome nav ul li ul.non_mega_menu li a:hover {
        color: #e45914;
        text-decoration: none;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu > li > a:before {
        content: normal;
    }

.tz-homeType2.tz-homeTypeRelative {
    position: relative;
    background: transparent;
}
.tz-homeType2.tz-homeTypeRelative nav ul.tz-nav > li > a {
    color: #242732;
}
.tz-homeType2.tz-homeType3 ul.tz-nav > li:last-child {
    padding-right: 0;
}
.tz_meetup_header_option {
    background: #e45914;
    padding: 7px 0;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
span.tz-search-header3 {
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 16px;
    margin-left: 30px;
}
.tz_meetup_header_option .tz_meetup_header_option_phone span {
    display: inline-block;
    color: #ffffff;
    line-height: 2.5em;
}
.tz_meetup_header_option .tz_meetup_header_option_phone span img {
    margin-right: 5px;
}
.tz_meetup_header_option .tz_meetup_header_option_phone span a {
    color: #ffffff;
    text-decoration: none;
}
.tz_meetup_header_option .tz_meetup_header_option_phone span:first-child {
    margin-right: 40px;
}
.tz-headerHome .tz-headerRight ul {
    margin: 0;
    padding: 0;
}
.tz-headerHome .tz-headerRight ul li {
    list-style: none;
    display: inline-block;
}
.tz-headerHome .tz-headerRight ul li a {
    display: inline-block;
    padding: 10px 12px;
}
.tz-headerHome .tz-headerRight ul li a i {
    font-size: 14px;
    color: #ffffff;
}
.tz-headerHome .tz-headerRight ul li:first-child a {
    padding-left: 0;
}
.tz-headerHome .tz-headerRight ul li:last-child a {
    padding-right: 0;
}
.tz-headerHome .tz-headerRight ul:after {
    display: block;
    content: "";
    clear: both;
}
.tz-headerHome .tz-headerSlider {
    position: relative;
}
.tz-headerRight ul li {
    padding: 0;
}
.tz-menuEffect,
.tz-homeEff {
    background: rgba(0, 0, 0, 0.7);
}
.tz-homeType2.tz-menuEffect-1 .tz_meetup_header_option {
    height: 0;
    padding: 0;
    overflow: hidden;
}
a.tz_upcoming_event {
    display: inline-block;
    background: #ffffff;
    text-decoration: none;
    font-weight: 700;
    padding: 1px 10px;
    margin-right: 15px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
a.tz_upcoming_event:hover {
    color: #e45914;
}
p.tz_description_event {
    display: inline-block;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2.6em;
    margin: 0;
}

/**
 * 3.0 - Slider Home
 * -----------------------------------------------------------------------------
 */
.tz_home_slider_meetup {
    position: relative;
    overflow: hidden;
}
.meetup_bl_slider_home {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    z-index: 50;
}
.tz_content_slider_meetup {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}
.tz_content_slider_meetup .tz_meetup_social {
    display: inline-block;
    position: relative;
    margin-bottom: 9px;
}
.tz_content_slider_meetup .tz_meetup_social .meetup_line_left {
    background: #ffffff;
    height: 1px;
    left: 100%;
    position: absolute;
    top: 50%;
    width: 25px;
}
.tz_content_slider_meetup .tz_meetup_social .meetup_line_right {
    background: #ffffff;
    height: 1px;
    right: 100%;
    position: absolute;
    top: 50%;
    width: 25px;
}
.tz_home_slider_meetup .tz_content_slider_meetup a {
    text-decoration: none;
    color: #ffffff;
    margin: 0 10px;
    font-size: 18px;
}
.tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text p {
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 0;
}
.tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text h4 {
    font-size: 60px;
    font-weight: 200;
    color: #ffffff;
    line-height: 1em;
    margin-bottom: 30px;
}
.tz_meetup_countdown {
    margin-top: -28px;
    margin-bottom: 14px;
}
.tz_meetup_countdown .tz_meetup_countdown_time {
    display: inline-block;
    position: relative;
    margin: 0 25px;
}
.tz_meetup_countdown .tz_meetup_countdown_time:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -25px;
    width: 1px;
    height: 25px;
    margin-top: -12.5px;
    background: #e45914;
}
.tz_meetup_countdown .tz_meetup_countdown_time:last-child:after {
    display: none;
}
.tz_meetup_countdown .tz_meetup_countdown_time span {
    font-size: 65px;
    color: #ffffff;
    display: block;
    line-height: 1.2em;
    font-weight: 100;
}
.tz_meetup_countdown .tz_meetup_countdown_time b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
}
.tz_meetup_countdown span.tz_meetup_countdown_over {
    color: #ffffff;
    display: inline-block;
    font-size: 30px;
    line-height: 1.4em;
    margin: 20px 0 0;
    text-transform: uppercase;
}
.tz_slider_home_btn_click {
    padding: 0;
    margin: 0;
}
.tz_slider_meetup_btn ul li {
    list-style: none;
    display: inline-block;
}
.tz_home_slider_meetup .tz_slider_meetup_btn a.tz_slider_meetup_btn_1 {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    background: #e45914;
    padding: 10px 35px;
    display: inline-block;
    font-size: 12px;
    border: 2px solid #e45914;
    margin: 0 10px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    letter-spacing: 3px;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_slider_meetup_btn a.tz_slider_meetup_btn_1.tz_slider_meetup_btn_2 {
    background: transparent;
    border-color: #ffffff;
}
.tz_slider_meetup_btn a.tz_slider_meetup_btn_1.tz_slider_meetup_btn_2:hover {
    background: #ffffff;
    color: #000;
}
.tz_slider_meetup_btn a.tz_slider_meetup_btn_1:hover {
    background: transparent;
}
.bx-wrapper .bx-controls-direction a {
    z-index: 50;
}

/**
 * 4.0 - Content
 * -----------------------------------------------------------------------------
 */

/* 4.1 - About */
#about {
    padding: 95px 0 85px;
}
.tz_maniva_meetup_title h3 {
    font-size: 26px;
    font-weight: 400;
    margin: 0 0 12px;
    text-transform: uppercase;
}
.tz_maniva_meetup_title h3.tz_maniva_meetup_title_custom {
    font-weight: 700;
}
.tz_meetup_title_content p {
    font-size: 16px;
    margin: 0;
}
#about_meetup {
    padding-bottom: 92px;
}
#about_meetup .custom_col_pading {
    padding-left: 7px;
    padding-right: 7px;
}
.tz_meetup_thumbnail img {
    border-radius: 5px;
    width: 100%;
}
.tz_maniva_about_meetup h4 {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 2px;
    margin: 25px 0 18px;
}
.tz_maniva_about_meetup h4 a {
    text-decoration: none;
    color: #242732;
}
.tz_meetup_thumbnail img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 100%;
}

/* 4.2 - Video Meetup */
.tz_bk_video_meetup {
    background-image: url("images/bk-meetup.jpg");
    padding: 75px 55px 81px 0;
}
h3.tz_meetup_general_title {
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 11px;
    margin-top: 0;
    font-weight: 700;
}
h3.tz_meetup_general_title em {
    font-weight: 400;
    font-style: normal;
}
.tz_image_title_meetup {
    margin-bottom: 30px;
}
span.tz_meetup_video_sub_title {
    display: inline-block;
    font-weight: 500;
    color: #e45914;
    text-transform: uppercase;
    margin-top: 5px;
}
span.tz_meetup_video_sub_title i.fa {
    color: #ffffff;
    font-size: 18px;
    margin-left: 10px;
}
span.tz_meetup_video_sub_title_line {
    display: block;
    border-bottom: 1px solid #42444d;
    padding-top: 5px;
}
.tz_box_text_bk .tz_meetup_content {
    margin-top: 10px;
}
.tz_meetup_content p {
    color: #dddddd;
    margin-bottom: 25px;
}
.tz_meetup_content > p:last-child {
    margin-bottom: 0;
}
a.tz_btn_video_meetup {
    font-weight: 500;
    text-decoration: none;
    border: 2px solid #fff;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 3px;
    margin-top: 50px;
    margin-left: 20px;
    padding: 12px 35px;
    position: relative;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
a.tz_btn_video_meetup:hover {
    color: #000000;
    background: #fff;
}
a.tz_btn_video_meetup.tz_meetup_btn_dark {
    color: #242732;
    border-color: #242732;
}
a.tz_btn_video_meetup.tz_meetup_btn_dark:hover {
    color: #fff;
    background: #242732;
}
a.tz_btn_video_meetup.tz_meetup_bnt_orange {
    color: #e45914;
    border-color: #e45914;
}
a.tz_btn_video_meetup.tz_meetup_bnt_orange:hover {
    color: #fff;
    background: #e45914;
}
a.tz_btn_video_meetup.tz_meetup_btn_dark.tz_btn_shop_meetup {
    background: #e45914;
    border: 2px solid #e45914;
    color: #ffffff;
}
a.tz_btn_video_meetup.tz_meetup_btn_dark.tz_btn_shop_meetup:hover {
    background: transparent;
    color: #242732;
}
a.tz_btn_video_meetup.tz_meetup_custom {
    margin:87px 0 0 0;
}
.tz_video_meetup {
    position: relative;
    overflow: hidden;
}
.tz_video_meetup img {
    width: 100%;
    max-width: none;
    position: relative;
}
.tz_video_meetup .tz_bk_video {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}
.tz_btn_play_video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.tz_btn_play_video a.tz_btn_easy {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #e9e9eb;
    background: rgba(255, 255, 255, 0.2);
    display: inline-block;
}
.tz_btn_play_video a.tz_btn_easy i.fa {
    line-height: 90px;
    color: #e45914;
    font-size: 30px;
    margin-left: 9px;
}

/* 4.3 - Counter */
#counter {
   padding: 83px 0 95px;
}
.tz-counter {
    text-align: center;
}
.tzCounter_type .tz-counter-font {
    display: block;
    margin-bottom: 24px;
}
.tzCounter_type .tz-counter-font i.fa {
    margin-bottom: 10px;
    color: #e45914;
    font-size: 36px;
}
.tzCounter_type .tz-counter-image {
    margin-bottom: 35px;
    height: 35px;
}
.tzCounter_type .tz-counter-number {
    display: block;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 20px;
}
.tzCounter_type .tz-counter-number:after {
    position: absolute;
    width: 24px;
    height: 4px;
    content: '';
    background: #c6c6c6;
    bottom: 0;
    left: 50%;
    margin-left: -12px;
}
.tzCounter_type .tz-counter-number em.stat-count {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 64px;
    color: #242732;
    font-style: normal;
}
.tzCounter_type p.tz-counter-title {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    color: #e45914;
}

/* 4.4 - Text Box Button */
#tz_text_box_button {
    padding-bottom: 104px;
}
#tz_text_box_button h3 {
    font-size: 28px;
    margin-bottom: 0;
}
#tz_text_box_button h3 span{
    color: #e45914;
}
#tz_text_box_button p {
    font-size: 16px;
    color: rgba(36,39,50,0.8);
}
.tz_meetup_btn a {
    text-decoration: none;
    color: #242732;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 3px;
    display: inline-block;
    padding: 13px 35px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border: 2px solid #000000;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_meetup_btn a:hover {
    background: #000000;
    color: #ffffff;
}

/* 4.5 - Slider Meetup */
#slider_meetup:after {
    display: table;
    clear: both;
    content: '';
}
#slider_meetup {
    position: relative;
    overflow: hidden;
}
.tz-full-slider-meetup {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
    left: 0;
}
.tz_slider_meetup {
    position: relative;
    overflow: hidden;
}
.tz_slider_meetup .tz_custom_images_slider {
    cursor: w-resize;
    overflow: hidden;
    position: relative;
}
.tz_slider_meetup .tz_custom_images_slider img {
    max-width: none;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    width: auto;
}
.tz_slider_meetup .tz_custom_images_slider .tz-bk-slider-meetup {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.48);
}
.tz_slider_meetup .owl-theme .owl-dots {
    position: absolute;
    bottom: 20px;
    right: 52%;
    margin: 0;
}
.tz-full-slider-meetup .tz_slider_meetup .owl-theme .owl-dots {
    bottom: auto;
    margin-top: -50px;
}
.tz_slider_meetup .owl-theme .owl-dots .owl-dot.active span {
    background: #e45914;
}
.tz_slider_meetup .owl-theme .owl-dots .owl-dot span {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #e45914;
    height: 15px;
    width: 15px;
    opacity: 1;
    margin-right: 0;
}
.tz_text_box_slider {
    padding: 0;
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 1;
    width: 50%;
    overflow: hidden;
    margin: 0 0 0 auto;
}
.tz_width_box {
    width: 50%;
}
.tz_text_box_slider_padding {
    padding: 75px 0 81px 80px;
}
.tz_meetup_general_title.tz_meetup_general_title_2 {
    color: #242732;
}
.tz_box_text_bk span.tz_meetup_video_sub_title i.fa {
    color: #242732;
}
span.tz_meetup_video_sub_title i.fa.tz_icon_position_left {
    margin-left: 0;
    margin-right: 10px;
}
.tz_box_text_bk span.tz_meetup_video_sub_title_line {
    border-bottom-color:#dddddd
}
.tz_box_text_bk .tz_meetup_content p span {
    color: #242732;
}

/* 4.6 - List */
.tz-plazart-list {
    margin: 17px 0 0;
    padding: 0;
}
.tz-plazart-list li {
    list-style: none;
    font-size: 16px;
    color: #242732;
    padding: 12px 0 8px;
    hyphens: none;
}
.tz-plazart-list li:last-child {
    margin-bottom: 35px;
}
.tz-plazart-list li:before {
    display: table;
    content: '';
    clear: both;
}
.tz-plazart-list li span.tz_icon_maniva_list {
    display: inline-block;
    float: left;
    line-height: 0;
    margin-top: 7px;
}
.tz-plazart-list li span.tz_icon_maniva_list i {
    color: #e54053;
}
.tz-plazart-list li span.tz_icon_maniva_list.tz_icon_maniva_list_style_1 i {
    color: #53c5a9;
}
.tz-plazart-list li p.tz_list_item_content {
    position: relative;
    margin-left: 30px;
    color: #242732;
    margin-bottom: 0;
}
.tz-plazart-list li p.tz_list_item_content:after {
    position: absolute;
    border-bottom: 1px dashed #dbd9d9;
    width: 100%;
    content: '';
    right: 0;
    bottom: -12px;
}
.tz_list_type {
    list-style: none;
    padding: 0;
}
.tz_list_type span.tz_icon_maniva_list {
    display: inline-block;
    margin-right: 10px;
    color: #53c5a9;
}
.tz_list_type .tz_list_item_content {
    display: inline-block;
}

/* 4.7 - Box text title line */
#box_text_title_line {
    background: #242732;
    padding: 85px 0;
}
.tz_bk_box_text_meetup {
    padding: 75px 80px 75px 0;
    background-image: url("images/bk-meetup-2.jpg");
}
.tz_maniva_meetup_title h3 em {
    font-weight: 400;
    font-style: normal;
}
.tz_maniva_meetup_title.tz_maniva_meetup_title_type3 {
    text-align: center;
    overflow: hidden;
}
.tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 {
    color: #f8f8f8;
    font-weight: 700;
    text-align: center;
    font-size: 24px;
    letter-spacing: 3px;
    position: relative;
    display: inline-block;
    padding: 0 45px;
    margin: 0;
}
.tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 .tz_title_line_left {
    position: absolute;
    left: 100%;
    background: #e45914;
    top: 50%;
    height: 1px;
    width: 100%;
}
.tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 .tz_title_line_right {
    position: absolute;
    right: 100%;
    background: #e45914;
    top: 50%;
    height: 1px;
    width: 100%;
}

/* 4.8 - Our Team meetup */
#speaker {
    overflow: hidden;
}
.tz_meetup_our_team_thumbnail {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.tz_meetup_our_team_thumbnail:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.4s linear 0.1s;
    -moz-transition: all 0.4s linear 0.1s;
    -webkit-transition: all 0.4s linear 0.1s;
    -ms-transition: all 0.4s linear 0.1s;
    -o-transition: all 0.4s linear 0.1s;
}
.tz_meetup_our_team_thumbnail:hover:after {
    background: rgba(0, 0, 0, 0.7);
}
.tz_meetup_our_team_thumbnail img {
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    width: 100%;
    max-width: none;
    position: relative;
}
.tz_meetup_our_team_thumbnail:hover img {
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}
.tz_meetup_our_team_thumbnail .tz_member_meetup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.tz_meetup_our_team_thumbnail .tz_member_meetup h4.tz_meetup_employment {
    font-weight: 600;
    font-size: 11px;
    color: #ffffff;
    letter-spacing: 1px;
    transform: translateY(30px);
    -moz-transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    opacity: 0;
    transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    text-transform: uppercase;
}
.tz_meetup_our_team_thumbnail .tz_member_meetup h3.tz_meetup_name {
    font-weight: 400;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1px;
    transform: translateY(30px);
    -moz-transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    opacity: 0;
    transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    text-transform: uppercase;
}
.tz_meetup_our_team_thumbnail:hover h4.tz_meetup_employment {
    transition: all 0.4s ease 0.2s;
    -moz-transition: all 0.4s ease 0.2s;
    -webkit-transition: all 0.4s ease 0.2s;
    -ms-transition: all 0.4s ease 0.2s;
    -o-transition: all 0.4s ease 0.2s;
    opacity: 1;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
.tz_meetup_our_team_thumbnail:hover h3.tz_meetup_name {
    transition: all 0.4s ease 0.35s;
    -moz-transition: all 0.4s ease 0.35s;
    -webkit-transition: all 0.4s ease 0.35s;
    -ms-transition: all 0.4s ease 0.35s;
    -o-transition: all 0.4s ease 0.35s;
    opacity: 1;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
span.tz_text_hover_speaker {
    text-transform: uppercase;
    color: #e45914;
    font-size: 12px;
    display: inline-block;
    background: #ffffff;
    line-height: 2.5em;
    padding: 0 25px;
    font-weight: 400;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    transform: translateY(30px);
    -moz-transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    opacity: 0;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz_member_meetup .tz_text_hover_speaker {
    background: #e45914;
    color: #ffffff;
}
.tz_our_speakers:hover .tz_text_hover_speaker {
    opacity: 1;
    transition: all 0.4s linear 0.1s;
    -moz-transition: all 0.4s linear 0.1s;
    -webkit-transition: all 0.4s linear 0.1s;
    -ms-transition: all 0.4s linear 0.1s;
    -o-transition: all 0.4s linear 0.1s;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
.tz_meetup_our_team_thumbnail:hover .tz_text_hover_speaker {
    opacity: 1;
    transition: all 0.4s ease 0.5s;
    -moz-transition: all 0.4s ease 0.5s;
    -webkit-transition: all 0.4s ease 0.5s;
    -ms-transition: all 0.4s ease 0.5s;
    -o-transition: all 0.4s ease 0.5s;
    transform: translateY(15px);
    -moz-transform: translateY(15px);
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
}

#our_speakers {
    padding: 110px 0 70px;
}
.tz_our_speakers_img {
    position: relative;
    overflow: hidden;
}
.tz_our_speakers_img:before {
    background: transparent;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
}
.tz_our_speakers_img img {
    width: 100%;
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
}
.tz_our_speakers_img .tz_our_speakers_social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
.tz_meetup_social_speakers {
    transform: translateY(30px);
    -moz-transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    opacity: 0;
    z-index: 1;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz_meetup_social_speakers a {
    color: #ffffff;
    text-decoration: none;
}
.tz_meetup_social_speakers a i.fa {
    padding: 0 10px;
    font-size: 15px;
}
span.tz_text_hover_speaker {
    text-transform: uppercase;
    color: #e45914;
    font-size: 12px;
    display: inline-block;
    background: #ffffff;
    line-height: 2.5em;
    padding: 0 25px;
    font-weight: 400;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    transform: translateY(30px);
    -moz-transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    opacity: 0;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz_our_speakers_container {
    text-align: center;
    border-left: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 5px solid #e7e7e7;
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    padding: 14px 0 15px;
}
.tz_our_speakers_container h4 {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 2px;
}
.tz_our_speakers_container span {
    font-size: 12px;
    color: #aaaaaa;
}
.tz_our_speakers {
    margin-bottom: 43px;
    cursor: pointer;
}
.tz_our_speakers:hover .tz_our_speakers_img:before {
    background: rgba(228, 89, 20, 0.8);
    z-index: 1;
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
}
.tz_our_speakers:hover .tz_our_speakers_container {
    border-bottom-color: #e45914;
}
.tz_our_speakers:hover img {
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}
.tz_our_speakers:hover .tz_meetup_social_speakers {
    opacity: 1;
    transition: all 0.4s linear 0s;
    -moz-transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
.tz_our_speakers:hover .tz_text_hover_speaker {
    opacity: 1;
    transition: all 0.4s linear 0.1s;
    -moz-transition: all 0.4s linear 0.1s;
    -webkit-transition: all 0.4s linear 0.1s;
    -ms-transition: all 0.4s linear 0.1s;
    -o-transition: all 0.4s linear 0.1s;
    transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.tz-md-modal-speakers span.md-close {
    display: block;
    text-align: right;
    padding: 10px 0;
    color: #0c1327;
    margin-bottom: 90px;
}
.tz-md-modal-speakers span.md-close.tz-md-close {
    text-align: center;
    margin-bottom: 20px;
}
.tz-md-close em {
    font-size: 14px;
    color: #e45914;
    text-transform: uppercase;
    margin: 15px 0;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    display: block;
}
.tz-md-modal-speakers span.md-close i.fa {
    font-size: 30px;
    cursor: pointer;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz-md-modal-speakers span.md-close i.fa:hover {
    color: #e45914;
}
.tz_modal_speakers_content_title {
    color: #e45914;
}
.tz_modal_speakers_content {
    padding: 0 15px;
}
.tz_modal_speakers_content:after {
    display: table;
    clear: both;
    content: '';
}
.tz_modal_speakers_content p {
    line-height: 1.8em;
    margin-bottom: 20px;
}
h4.tz_modal_name {
    color: #e45914;
    font-size: 30px;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    line-height: 1em;
    margin-bottom: 5px;
}
span.tz_modal_employment {
    display: block;
    color: #898b90;
    margin-bottom: 4px;
}
.tz_modal_speakers_content .ezcol span {
    font-size: 18px;
}
.tz_modal_speakers_content .ezcol span i {
    font-size: 14px;
}
.tz_modal_speakers_content .tz-plazart-list li span.tz_icon_maniva_list {
    margin-top: 3px;
}
.tz_modal_speakers_content .tz-plazart-list {
    margin: 0;
}
.tz_modal_speakers_content .tz-plazart-list li p {
    line-height: 1.5em;
    font-size: 14px;
}
.tz_modal_title {
    text-align: center;
    margin-bottom: 15px;
}
.tz_modal_image {
    width: 250px;
    height: 250px;
    overflow: hidden;
    display: inline-block;
    position: absolute;
    border: 1px solid rgba(0,0,0,0.1);
    top: -125px;
    left: 50%;
    margin-left: -125px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.tz_modal_image_content {
    border: 5px solid rgba(255,255,255,0.5);
    display: block;
    overflow: hidden;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.tz-md-modal-speakers img {
    width: 100%;
}
.tz_modal_speakers_social {
    text-align: center;
}
.tz_modal_speakers_social a {
    color: #898b90;
    margin: 0 5px;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_modal_speakers_social a:hover {
    color: #e45914;
    text-decoration: none;
}
.tz-plazart-list li span.tz_icon_maniva_list_custom i {
    color: #53c5a9;
}

/* 4.9 - Tab Event */
#agenda {
    padding: 84px 0 135px;
}
.tz_tab_custom {
    margin-top: 48px;
}
.tz_tabs_meetup {
    position: relative;
}
.tz_tabs_meetup:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    height: 1px;
    width: 16%;
    background: -moz-radial-gradient(center left, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -o-radial-gradient(center left, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -ms-radial-gradient(center left, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -webkit-radial-gradient(center left, circle farthest-side, #ffffff 60%, #242732 100%);
    z-index: 1;
}
.tz_tabs_meetup:after {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    height: 1px;
    width: 16%;
    background: -moz-radial-gradient(center right, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -o-radial-gradient(center right, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -ms-radial-gradient(center right, circle farthest-side, #ffffff 60%, #242732 100%);
    background: -webkit-radial-gradient(center right, circle farthest-side, #ffffff 60%, #242732 100%);
    z-index: 1;
}
.tz_tab_custom .nav-tabs {
    text-align: center;
    border-bottom: none;
    position: relative;
}
.tz_tab_custom .nav-tabs:after {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    content: '';
    height: 1px;
    background: #242732;
}
.tz_tab_custom .nav-tabs > li {
    margin: 0 5px 1px 0;
    float: none;
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
}
.tz_tab_custom .nav-tabs > li > a {
    font-size: 11px;
    font-weight: 500;
    color: #242732;
    text-transform: uppercase;
    text-shadow: none;
    position: relative;
    padding: 6px 30px;
    margin-right: 0;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border: 1px solid #242732;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_tab_custom .nav-tabs > li.active > a,
.tz_tab_custom .nav-tabs > li.active > a:focus,
.tz_tab_custom .nav-tabs > li.active > a:hover,
.tz_tab_custom .nav-tabs > li > a:hover {
    background: #e45914;
    border-color: #e45914;
    color: #ffffff;
}
.tz_tab_content {
    margin-top: 70px;
}
.tz_box_event_meetup h3 {
    font-size: 20px;
    text-transform: uppercase;
    color: #e45914;
    letter-spacing: 1px;
    margin: 0 0 5px;
}
.tz_box_event_meetup h3.tz_event_meetup_subtitle {
    line-height: 1.1em;
    color: #666666;
    font-size: 14px;
}
.tz_box_event_meetup h3.tz_event_meetup_subtitle em {
    font-weight: 400;
    font-style: inherit;
}
.tz_event_meettup_box_content {
    position: relative;
}
.tz_event_meettup_box_content:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 50px;
    width: 3px;
    background: -moz-linear-gradient(top, #ffffff 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(35%, rgba(255, 255, 255, 0.65)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(top, #ffffff 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(top, #ffffff 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(top, #ffffff 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
    z-index: 1;
}
.tz_event_meettup_box_content:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 130px;
    width: 3px;
    background: -moz-linear-gradient(bottom, #ffffff 30%, rgba(238, 238, 238, 0) 100%);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, #ffffff), color-stop(100%, rgba(238, 238, 238, 0)));
    background: -webkit-linear-gradient(bottom, #ffffff 30%, rgba(238, 238, 238, 0) 100%);
    background: -o-linear-gradient(bottom, #ffffff 30%, rgba(238, 238, 238, 0) 100%);
    background: -ms-linear-gradient(bottom, #ffffff 30%, rgba(238, 238, 238, 0) 100%);
    background: linear-gradient(to top, #ffffff 30%, rgba(238, 238, 238, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00eeeeee', GradientType=0);
    z-index: 1;
}
.tz_event_meetup_content {
    border-left: 3px solid #eeeeee;
    padding-left: 125px;
    margin-top: 40px;
    position: relative;
}
.tz_event_meetup_content .tz_meetup_box_detail {
    position: relative;
    margin-bottom: 20px;
    display: block;
}
.tz_event_meetup_content .tz_meetup_box_detail .tz_meetup_box_detail_custom {
    display: inline-block;
    padding: 24px 40px 25px 22px;
    background: #f8f8f8;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.tz_event_meetup_content .tz_meetup_box_detail:before {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #e45914;
    content: '';
    top: 45px;
    left: -136px;
    border: 5px solid #ffffff;
    z-index: 2;
}
.tz_event_meetup_content .tz_meetup_box_detail:after {
    border-bottom: 20px solid #f8f8f8;
    border-left: 20px solid transparent;
    border-top: 20px solid transparent;
    content: "";
    left: -20px;
    position: absolute;
    top: 15px;
}
.tz_event_meetup_content .tz_meetup_box_detail span.tz_meetup_start_time {
    color: #242732;
    left: -110px;
    position: absolute;
    top: 45px;
}
.tz_event_meetup_content .tz_meetup_box_detail p {
    font-size: 11px;
}
.tz_event_meetup_content .tz_meetup_box_detail p i.fa {
    color: #e45914;
}
.tz_event_meetup_content .tz_meetup_box_detail h4 {
    color: #595c6a;
    font-size: 15px;
    text-transform: uppercase;
}
.tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content h5 {
    font-weight: 200;
    color: #0b0c0f;
}
.tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p {
    margin: 0;
    font-size: 13px;
}
.tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p:first-child {
    margin-top: 10px;
}
.tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p em {
    color: #888a8e;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
    display: inline-block;
}
#tab {
    padding: 84px 0 135px;
}
.tz_custom_btn_padding {
    padding: 0 0 0 125px;
}
.tz_custom_height_empty {
    height: 60px;
}

/* 4.10 - Twitter */
#tz_twitter {
    position: relative;
    background-image: url("images/bk-paralax-4.jpg");
}
#tz_twitter:after {
    position: absolute;
    content: '';
    background-color: rgba(9, 9, 31, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.tzTwitter-slider-box {
    padding: 148px 0 142px;
}
.tzTwitter-slider-box {
    position: relative;
    z-index: 2;
}
.tzTwitter-item {
    text-align: center;
}
.tzTwitter-item .tzTwitter-icon {
    background-image: url("images/border-tw.png");
    background-position: center;
    background-repeat: no-repeat;
    padding: 28px 0 32px;
}
.tzTwitter-item .tzTwitter-icon i.fa {
    font-size: 40px;
    color: #ffffff;
}
.tzTwitter-item span.tweet_text {
    font-weight: 300;
    font-size: 30px;
    color: #eeeeee;
    display: block;
    line-height: 1.6em;
    margin: 35px 0 40px 0;
}
.tzTwitter-item span.tweet_text a {
    color: #e45914;
}
.tzTwitter-item span.tz_tweet_meetup_name {
    margin-bottom: 5px;
    color: #aaaaaa;
    display: block;
}
.tzTwitter-item span.tweet_time {
    display: block;
    font-size: 15px;
    color: #eeeeee;
}
.tzTwitter-slider-box .tzTwitter-slider.owl-theme .owl-dots {
    margin-top: 58px;
}
.tzTwitter-slider.owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 20px;
    border: 2px solid #e45914;
    background: transparent;
    opacity: 1;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    margin: 0 5px;
}
.tzTwitter-slider.owl-theme .owl-dots .owl-dot span:hover {
    background: #e45914;
}
.tzTwitter-slider.owl-theme .owl-dots .owl-dot.active span {
    background: #e45914;
}

/* 4.11 - Gallery */
#gallery {
    background-color: #242732;
    padding: 90px 0 0;
}
#gallery h3.tz_meetup_title_raleway,
#speaker_client .tz_maniva_meetup_title h3,
.tz_maniva_meetup_title_custom_white {
    color: #ffffff;
}
#gallery .tz_meetup_title_content p span {
    color: #95979d;
}
#tz_plusg_gallery {
    padding-top: 100px;
}

/* 4.12 - Box text title_2 */
#box_text_title_2 {
    background-color: #e45914;
    padding: 75px 0 80px;
    position: relative;
}
.tz_meetup_btn h4.tz_meetup_title_btn {
    color: #ffffff;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 35px;
    font-weight: 600;
}
.tz_meetup_btn a.tz_meetup_btn_white {
    color: #ffffff;
    border-color: #ffffff;
}
.tz_meetup_btn a.tz_meetup_btn_white:hover {
    background: #ffffff;
    color: #000000;
}
.tzRow_ArrowType4 {
    border-left: 60px solid rgba(0, 0, 0, 0);
    border-right: 60px solid rgba(0, 0, 0, 0);
    border-top: 30px solid #e45914;
    left: 50%;
    margin-left: -62px;
    position: absolute;
    top: 100%;
    z-index: 1;
}

/* 4.13 - Speaker client */
.tz-partner {
    position: relative;
}
#speaker_client {
    background-image: url("images/bk-101.png");
    background-repeat: no-repeat;
    padding: 110px 0 120px;
}
.partner-slider {
    cursor: w-resize;
    list-style: none;
    margin: 0;
    padding: 0;
}
.partner-slider li {
    text-align: center;
    padding: 0;
    list-style: none;
}
.partner-slider.owl-carousel .owl-item img {
    width: auto;
    margin: 0 auto;
}
.tz_meetup_btn a.tz_meetup_bnt_orange {
    color: #e45914;
    border-color: #e45914;
}
.tz_meetup_btn a.tz_meetup_bnt_orange:hover {
    background: #e45914;
    color: #ffffff;
}
.tz_meetup_btn a.tz_meetup_bnt_orange_bk {
    background: #e45914;
    color: #ffffff;
    border-color: #e45914;
}
.tz-partner .tz_partner_prevs_type2 {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: -75px;
    font-size: 60px;
    margin-top: -30px;
    color: #aaaaaa;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
.tz-partner .tz_partner_prevs_type2:hover {
    color: #e45914;
}
.tz-partner .tz_partner_nexts_type2 {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: -75px;
    font-size: 60px;
    margin-top: -30px;
    color: #aaaaaa;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
.tz-partner .tz_partner_nexts_type2:hover {
    color: #e45914;
}

/* 4.14 - FAQ */
#faq {
    padding: 113px 0 142px;
}
.tz_maniva_meetup_title h3.tz_meetup_title_raleway.tz_title_meetup_semi_bold {
    font-weight: 600;
    color: #242732;
}
.tz_meetup_ask_question {
    margin-bottom: 75px;
}
.tz_meetup_question:after {
    display: table;
    content: '';
    clear: both;
}
.tz_meetup_question span.tz_icon_meetup_question {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #e45914;
    text-align: center;
    float: left;
    margin-top: 4px;
}
.tz_meetup_question span.tz_icon_meetup_question i.fa {
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
}
.tz_meetup_question h4 {
    margin-left: 40px;
    font-size: 18px;
    font-weight: 600;
}
.tz_meetup_ask {
    padding-left: 40px;
    margin-top: 10px;
}
.tz_meetup_ask p {
    font-weight: 300;
}

/* 4.15 - Register */
#register {
    background-image: url("images/bk-paralax-8.jpg");
    position: relative;
}
#register:after {
    background-color: rgba(9, 9, 31, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.tz_padding_register {
    padding: 95px 0;
}
.tz_register_meetup {
    width: 585px;
    margin: 0 0 0 auto;
    position: relative;
    z-index: 1;
}
.tz_register_meetup_pricing_item {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz_register_meetup_pricing_item:after {
    clear: both;
    content: '';
    display: table;
}
.tz_register_meetup_pricing_item:hover .tz_register_meetup_pricing_item_container {
    background: #e45914;
}
.tz_register_meetup_pricing_item:hover .tz_register_meetup_pricing_item_container h3,
.tz_register_meetup_pricing_item:hover .tz_register_meetup_pricing_item_container p {
    color: #ffffff;
}
.tz_register_meetup_pricing_item:hover .tz_register_meetup_pricing_item_price:after {
    position: absolute;
    top: 50%;
    right: -15px;
    content: '';
    border-bottom: 25px solid transparent;
    border-top: 25px solid transparent;
    border-left: 25px solid #e45914;
    margin-top: -22px;
    z-index: -1;
}
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_container {
    background: #e45914;
}
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_container h3,
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_container p {
    color: #ffffff;
}
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_price:after {
    position: absolute;
    top: 50%;
    right: -15px;
    content: '';
    border-bottom: 25px solid transparent;
    border-top: 25px solid transparent;
    border-left: 25px solid #e45914;
    margin-top: -22px;
    z-index: -1;
}
.tz_register_meetup_pricing {
    margin-right: 68px;
    margin-left: 30px;
}
.tz_register_meetup_pricing .tz_register_meetup_pricing_item:last-child {
    margin-bottom: 0;
}
.tz_register_meetup_pricing_item_container {
    padding: 21px 20px;
    width: 70%;
    float: left;
    background: #ffffff;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
}
.tz_register_meetup_pricing_item_container h3 {
    color: #5c5e63;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
}
.tz_register_meetup_pricing_item_container p {
    line-height: 1.6em;
    margin: 0;
}
.tz_register_meetup_pricing_item_price {
    width: 30%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-image: url("images/price-pay.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #e45914;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
}
.tz_register_meetup_pricing_item_price h3 {
    color: #ffffff;
    font-size: 40px;
    font-family: "Roboto", serif;
    font-weight: 500;
}
.tz_form_register {
    background-color: rgba(36, 39, 50, 0.9);
    padding: 100px 0 95px 80px;
}
.tz_form_register_box {
    position: relative;
    z-index: 1;
    width: 55%;
}
.tz_form_register_box .tz_meetup_title_content p {
    color: #95979d;
}
.tz_meetup_form {
    margin: 48px 0 0;
}
.tz_meetup_form p {
    margin-top: 15px;
}
.tz_meetup_form label {
    font-weight: 500;
    font-size: 13px;
    color: #7e8394;
    text-transform: uppercase;
    margin-bottom: 6px;
}
.tz_meetup_form p span {
    display: block;
}
.tz_meetup_form p span input {
    width: 100%;
    padding: 13px 20px;
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #53586b;
    font-size: 15px;
    line-height: 1.4em;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz_meetup_form p span input:focus {
    border: 2px solid #e45914;
}
p.tz_meetup_btn_paypal {
    margin: 45px 0 0;
}

.tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input {
    position: relative;
    display: block;
}
.tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 80%;
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 2px;
    width: 40px;
    z-index: 1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.tz_meetup_form.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input span {
    display: block;
}
.tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input span:before {
    position: absolute;
    content: "\f0d7";
    font-family: 'FontAwesome';
    bottom: 18px;
    right: 25px;
    pointer-events: none;
    font-size: 18px;
    z-index: 2;
}
.tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input span:after {
    position: absolute;
    content: "\f0d8";
    font-family: 'FontAwesome';
    top: 18px;
    right: 25px;
    pointer-events: none;
    font-size: 18px;
    z-index: 2;
}
@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
    .tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input:before {
        right: 2px;
    }
    .tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input span:before,
    .tz_meetup_form p.tz_meetup_quantity_paypal sub.tz_meetup_quantity_paypal_input span:after {
        right: 10px;
    }
}
p.tz_meetup_btn_paypal input.tz_paypal_submit {
    background: none;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border: 2px solid #ffffff;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 13px 35px;
}
/* 4.16 - Recent posts */
#recent_post {
    padding: 92px 0 115px;
}
.tz_meetup_slider_blog {
    cursor: w-resize;
}
.tz_meetup_btn_post {
    text-align: center;
    margin-top: 98px;
}
.tz_meetup_btn_post a {
    border: 2px solid #000000;
    border-radius: 25px;
    color: #242732;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    padding: 13px 35px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_meetup_btn_post a:hover {
    background: #000000 none repeat scroll 0 0;
    color: #ffffff;
}
.tz_recent_blog_meetup_content {
    padding: 0 15px;
}
.tz_recent_blog_meetup_content:hover .tz_meetup_slider_img:before,
.tz_recent_blog_meetup_content:hover .tz_meetup_post_img:before {
    background: rgba(0, 0, 0, 0.62);
}
.tz_recent_blog_meetup_content:hover .tz_meetup_view_link span a {
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1;
}
.tz_recent_blog_meetup {
    position: relative;
}
.tz_recent_blog_meetup button {
    background: transparent;
    border: none;
    position: absolute;
    color: #b0b2b6;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #b0b2b6;
    font-size: 32px;
}
.tz_recent_blog_meetup button.tz_recent_blog_pev_meetup {
    left: -125px;
    top: 50%;
    padding: 0 3px 3px 0;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_recent_blog_meetup button.tz_recent_blog_pev_meetup:hover {
    background: #e45914;
    color: #ffffff;
    border: 1px solid #e45914;
}
.tz_recent_blog_meetup button.tz_recent_blog_next_meetup {
    right: -125px;
    top: 50%;
    padding: 0 0 3px 3px;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_recent_blog_meetup button.tz_recent_blog_next_meetup:hover {
    background: #e45914;
    color: #ffffff;
    border: 1px solid #e45914;
}
.tz_meetup_slider_img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.tz_meetup_slider_img ul.slides li {
    display: none;
    padding: 0;
}
.tz_meetup_slider_img ul.slides li:first-child {
    display: block;
}
.tz_meetup_slider_img img {
    width: 100%;
}
.tz_meetup_slider_img:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    z-index: 1;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_meetup_slider_img .flex-direction-nav a {
    color: #ffffff;
}
.tz_meetup_slider_img .flex-direction-nav a:before {
    font-size: 20px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
}
.tz_meetup_slider_img .flex-direction-nav .flex-prev {
    right: 46px;
    opacity: 1;
    left: auto;
    top: auto;
    bottom: 12px;
}
.tz_meetup_slider_img .flex-direction-nav .flex-next {
    right: 20px;
    opacity: 1;
    top: auto;
    bottom: 12px;
}
.tz_meetup_post_img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.tz_meetup_post_img:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    z-index: 1;
}
.tz_meetup_post_img img {
    width: 100%;
}
.tz_recent_blog_meetup_detail h4 {
    font-size: 15px;
    text-transform: uppercase;
    margin: 25px 0 20px;
}
.tz_recent_blog_meetup_detail h4 a {
    color: #242732;
    text-decoration: none;
}
span.tz_meetup_infomation {
    font-style: italic;
    display: block;
    margin-top: 2px;
}
span.tz_meetup_infomation a {
    color: #95979d;
    text-decoration: none;
    font-size: 12px;
    margin-right: 10px;
}
span.tz_meetup_infomation small {
    font-size: 12px;
    margin-right: 10px;
}
span.tz_meetup_infomation small i.fa {
    margin-right: 5px;
}
.tz_image_recent_blog_meetup {
    position: relative;
    cursor: pointer;
}
.tz_image_recent_blog_meetup .tz_recent_blog_meetup_date {
    position: absolute;
    top: 0;
    left: 0;
    background: #e45914;
    z-index: 1;
    padding: 13px 15px;
    text-align: center;
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -ms-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    -ms-border-bottom-right-radius: 5px;
    -o-border-bottom-right-radius: 5px;
}
.tz_image_recent_blog_meetup .tz_recent_blog_meetup_date span {
    display: block;
    font-family: 'Lato', sans-serif;
}
.tz_image_recent_blog_meetup .tz_recent_blog_meetup_date span.tz_month_latest {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 20px;
}
.tz_image_recent_blog_meetup .tz_recent_blog_meetup_date span.tz_date_latest {
    color: #e5c9bb;
    font-size: 17px;
}
.tz_meetup_view_link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.tz_meetup_view_link span a {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-size: 18px;
    margin: 1px;
    padding: 8px;
    text-decoration: none;
    opacity: 0;
    transform: scale(0.5);
    -moz-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz_meetup_slider_blog.owl-theme .owl-dots {
    display: none;
}

/* 4.17 - Contact */
#contact:after {
    background-color:rgba(9,9,31,0.45);
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
}
#contact {
    background-image: url("images/bk-images-31.jpg");
    position: relative;
}
.vc_column_custom {
    padding: 156px 0 104px;
}
.tzContact {
    position: relative;
    z-index: 1;
}
.TzContactInfo {
    display: inline-block;
    padding: 95px 0 110px 0;
    margin: 0;
}
.TzContactInfo li {
    font-size: 16px;
    color: #aaaaaa;
    margin-bottom: 22px;
    list-style: none;
    border-bottom: 1px solid #4f4f4f;
    padding-bottom: 20px;
}
.TzContactInfo li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.TzContactInfo li.tzContactPhone {
    font-size: 20px;
    line-height: 1.3em;
    margin: 0;
}
.TzContactInfo li a {
    color: #e45914;
    text-decoration: none;
}
.TzContactInfo h4 {
    font-size: 20px;
    color: #dddddd;
    margin-bottom: 5px;
}
.tzContact_2 {
    width: 75%;
    margin: 0 auto;
}
.tzContact_2_content {
    margin-bottom: 50px;
}
.tzContact_2_content.tzContact_2_content_center {
    margin-bottom: 37px;
}
.tzContact_2_content h3 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    color: #e45914;
    font-size: 24px;
    margin-bottom: 0;
}
.tzContact_2_content h4 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 24px;
    line-height: 1.2em;
    margin-bottom: 0;
}
.tzContact_2_content h5 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    margin: 0;
}
.tzContact_2_content p {
    font-family: Roboto, sans-serif;
    color: #aaaaaa;
    font-size: 16px;
    margin: 0;
    font-weight: 300;
    line-height: 2em;
}
.tzContact_2_content p a {
    color: #aaaaaa;
    text-decoration: none;
}
.tzContact_2_content p span.tz_fa_icon {
    display: inline-block;
    width: 18px;
    height: 16px;
    text-align: center;
    background: #e45914;
    line-height: 17px;
    font-size: 11px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    margin-right: 5px;
}
.tzContact_2_content p span.tz_fa_icon i {
    color: #333333;
}
.tz_map_meetup {
    position: relative;
    overflow: hidden;
}
.tz_map_meetup:after {
    background: rgba(0, 0, 0, 0.5);
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    height: 100%;
}
.tz_map_meetup .tz_map_meetup_bk {
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    height: 100%;
    background: rgba(9, 9, 31, 0.4);
}
.tz_map_meetup iframe {
    width: 100%;
    height: 100%;
    border: none;
}
.tz_map_meetup .tz_meet_gmap_contact {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.tz_map_meetup .tz_meet_gmap_contact a {
    border: 2px solid #ffffff;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    padding: 13px 35px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
}
.tz_map_meetup .tz_meet_gmap_contact a:hover {
    color: #000000;
    background: #ffffff;
}

/* 4.18 - Breadcrumb */
.tz-sectionBreadcrumb {
    background-color: #434758;
    background-image: url("images/breadcrumb_meetup.png");
    background-repeat: no-repeat;
    padding: 60px 0;
    background-size: cover;
}
.tz-sectionBreadcrumb h1.tz-sectionBreadcrumb-month {
    color: #ffffff;
}
.tz-sectionBreadcrumb .tz_breadcrumb_single_cat_title h4 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7em;
    text-transform: uppercase;
}
.tz-sectionBreadcrumb .tz-breadcrumb {
    text-align: right;
}
.tz-sectionBreadcrumb .tz-breadcrumb.tz-breadcrumb_archive {
    text-align: left;
}
.tz-sectionBreadcrumb .tz-breadcrumb h4 {
    font-size: 14px;
    line-height: 1.7em;
    text-transform: capitalize;
    color: #ffffff;
}
.tz-sectionBreadcrumb .tz-breadcrumb h4 span {
    color: #ffffff;
    font-weight: 400;
}
.tz-sectionBreadcrumb .tz-breadcrumb h4 span a {
    color: #ffffff;
    text-decoration: none;
}

/* 5.0 - Blog */
.tz-blogDefault {
    padding: 100px 0 80px;
}
.tz-blogDefault.tz_blogDefault_breadcrumb {
    padding-top: 40px;
}
.tz-blogDefault .tz-blogItem:after {
    content: '';
    display: block;
    clear: both;
}
.tz-blogDefault .tz-blogItem .tz-blogContent {
    margin-bottom: 40px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox {
    border-bottom: 2px solid #e45914;
    padding: 30px 30px 35px;
    background: #f8f8f9;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage {
    position: relative;
    margin-bottom: 32px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage .tz-ImageOverlay {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a {
    position: absolute;
    top: 30%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-top: -20px;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    visibility: hidden;
    opacity: 0;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a i {
    color: #000;
    padding: 14px 0;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a i.fa-link {
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a.tz-ViewImage {
    left: 50%;
    margin-left: -44px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a.tz-ViewDetail {
    right: 50%;
    margin-right: -44px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a:hover i {
    color: #fff;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage:hover .tz-ImageOverlay {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage:hover a.tz-ViewImage {
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in 0s;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -ms-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage:hover a.tz-ViewDetail {
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in 0.1s;
    -moz-transition: all 0.3s ease-in 0.1s;
    -webkit-transition: all 0.3s ease-in 0.1s;
    -ms-transition: all 0.3s ease-in 0.1s;
    -o-transition: all 0.3s ease-in 0.1s;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox h4.title {
    margin: 0 0 8px;
    padding: 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2em;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox h4.title a {
    color: #242732;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox h4.title a:hover {
    text-decoration: none;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation {
    display: block;
    margin-bottom: 28px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a:hover span,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tz-BlogImage a:hover {
    background-color: #e45914;
    transition: none;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox p {
    color: #646569;
    margin-bottom: 12px;
    line-height: 1.7em;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox p span {
    color: #e45914;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox a.tzreadmore {
    color: #242732;
    display: block;
    font-size: 11px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 2px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox a.tzreadmore i {
    margin-left: 10px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox a.tzreadmore span:hover {
    color: #e45914;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox.tz-blogBoxSlider {
    background: #242732;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox.tz-blogBoxSlider h4.title a {
    color: #ffffff;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation small,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation span,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation a {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: italic;
    color: #95979d;
    text-decoration: none;
    text-shadow: none;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation > small,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation > span,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation > a {
    margin-right: 10px;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation small i,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation a i,
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation span i {
    margin-right: 10px;
    color: #cccccc;
}
.tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox .tzinfomation small.tzinfomation_time {
    font-style: normal;
}
.tz-blogDefault .wp-pagenavi {
    text-align: right;
    margin-top: 90px;
}
.tz-blogDefault .wp-pagenavi a,
.tz-blogDefault .wp-pagenavi span {
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    text-decoration: none;
    padding: 10px 15px;
    border: 1px solid #dddddd;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    display: inline-block;
}
.tz-blogDefault .wp-pagenavi span.current,
.tz-blogDefault .wp-pagenavi a:hover,
.tz-blogDefault .wp-pagenavi span:hover {
    background-color: #e45914;
    border: 1px solid #e45914;
    text-decoration: none;
    color: #ffffff;
}

/* Masory */
.blogMasonry-item.tz-grid-3 {
    width: 33.33333333%;
    float: left;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner {
    margin: 0 30px 30px 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage {
    position: relative;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage img {
    width: 100%;
    height: auto;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage .tz-ImageOverlay {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in 0s;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a {
    position: absolute;
    top: 30%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-top: -20px;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    visibility: hidden;
    opacity: 0;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a i {
    color: #000;
    padding: 14px 0;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a.tz-ViewImage {
    left: 50%;
    margin-left: -44px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a.tz-ViewDetail {
    right: 50%;
    margin-right: -44px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a:hover i {
    color: #fff;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage:hover .tz-ImageOverlay {
    background-color: rgba(0, 0, 0, 0.25);
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage:hover a.tz-ViewImage {
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in 0s;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -ms-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage:hover a.tz-ViewDetail {
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in 0.1s;
    -moz-transition: all 0.3s ease-in 0.1s;
    -webkit-transition: all 0.3s ease-in 0.1s;
    -ms-transition: all 0.3s ease-in 0.1s;
    -o-transition: all 0.3s ease-in 0.1s;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate .tz-DateText {
    width: 50px;
    height: 50px;
    background: #e45914;
    padding: 5px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate .tz-DateText .tz-TextDay {
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate .tz-DateText .tz-TextMonth {
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate .tz-blogIcon {
    width: 50px;
    height: 50px;
    background: #444444;
    text-align: center;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogDate .tz-blogIcon i {
    font-size: 16px;
    color: #fff;
    padding: 16px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogSlider {
    position: relative;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogSlider ul.slides li img {
    width: 100%;
    height: auto;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo {
    border: 1px solid #dddddd;
    padding: 15px 18px 20px;
    border-bottom: 2px solid #e45914;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo h4.title {
    margin: 0;
    padding: 0;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo h4.title a {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo h4.title a:hover {
    text-decoration: none;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a:hover span,
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-BlogImage a:hover {
    background-color: #e45914;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo .tzinfomation {
    display: block;
    margin-bottom: 12px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo .tzinfomation small i {
    padding-right: 5px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo .tzinfomation a:hover {
    text-decoration: none;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo p {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: #444444;
    margin: 0 0 15px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo a.tzreadmore {
    color: #42484d;
    display: block;
    font-family: "Roboto Slab", serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 2px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo a.tzreadmore i {
    margin-left: 10px;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo a.tzreadmore:hover {
    text-decoration: none;
    color: #e45914;
}
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo .tzinfomation small,
.tz-blogMasonry .tzBlogmasonry .blogMasonry-item .tz-blogInner .tz-blogInfo .tzinfomation a {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
    line-height: 20px;
    color: #79797e;
    margin-right: 5px;
}
.tz-blogMasonry .wp-pagenavi {
    display: block;
    text-align: center;
    margin: 91px 0 29px;
}

/* single */
.tz-blogSingle {
    padding: 100px 0;
}
.tz-blogSingle .tz-blogSingleContent {
    padding-bottom: 50px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox {
    padding: 40px 40px 0;
    background: #f8f8f9;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general {
    position: relative;
    margin-bottom: 85px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz-blogAudio iframe.iframe-full-audio {
    border: none;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz-blogVideo iframe.iframe-full {
    border: none;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author {
    position: absolute;
    bottom: -58px;
    left: 30px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author.tz_SingleContentBox_author_hide {
    bottom: -25px;
    left: 0;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author .author-avata {
    display: inline-block;
    position: relative;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author .author-avata img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.5);
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social {
    display: inline-block;
    margin-left: 8px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink {
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
    border: 5px solid rgba(255, 255, 255, 0.3);
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    line-height: 40px;
    display: inline-block;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span.TzSocialLink_fa {
    background: #4396e6;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span.TzSocialLink_twitter {
    background: #15c0cc;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span.TzSocialLink_linkedin {
    background: #4c5866;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span.TzSocialLink_dribbble {
    background: #4c5866;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author span.author-social a.TzSocialLink span.TzSocialLink_gp {
    background: #f56048;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    text-align: right;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .entry-single-comment {
    color: #95979d;
    border: 1px solid #dce2ed;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    padding: 5px 15px;
    display: inline-block;
    font-family: 'Lato', sans-serif;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .entry-single-comment a {
    display: block;
    text-decoration: none;
    color: #95979d;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap {
    display: inline-block;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap p {
    color: #95979d;
    border: 1px solid #dce2ed;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    padding: 5px 15px;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content {
    position: absolute;
    top: 120%;
    right: 0;
    width: 100%;
    transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform-origin: right center;
    -moz-transform-origin: right center;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content a {
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content a.tz_social_facebook {
    background: #4396e6;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content a.tz_social_twitter {
    background: #15c0cc;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content a.tz_social_gplus {
    background: #4c5866;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-content a.tz_social_pinterest {
    background: #f56048;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like .share-wrap .share-wrap-full {
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-blogSlider {
    position: relative;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox h4.tzSingleBlog_title {
    font-size: 30px;
    margin: 0 0 3px;
    padding: 0;
    font-weight: 600;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation {
    display: block;
    margin-bottom: 18px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .single-content {
    margin-bottom: 52px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .single-content p {
    color: #646569;
    line-height: 1.8em;
    margin-bottom: 25px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation small,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation a {
    font-family: 'Lato', sans-serif;
    font-style: italic;
    color: #95979d;
    margin-right: 10px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation small.tzinfomation_time {
    margin-right: 3px;
    font-style: normal;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation small.tzinfomation_time.tzinfomation_time_last,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation a i,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tzinfomation span.tzcategory i {
    margin-right: 10px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox span.tztag {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #242732;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox span.tztag a {
    font-family: 'Lato', sans-serif;
    display: inline-block;
    color: #95979d;
    padding: 5px 20px;
    margin-left: 5px;
    margin-bottom: 8px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border: 1px solid #dce2ed;
    font-weight: 400;
    text-decoration: none;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox span.tztag a:first-child {
    margin-left: 38px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox span.tztag a:hover {
    color: #ffffff;
    background-color: #e45914;
    border-color: #e45914;
}
.tz-blogSingle .author {
    padding-bottom: 50px;
    margin-bottom: 45px;
    background: #f8f8f9;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: hidden;
    border-bottom: 5px solid rgba(0, 0, 0, 0.02);
}
.tz-blogSingle .author .author-box {
    padding: 30px 55px 26px 25px;
}
.tz-blogSingle .author .author-box .author-avata {
    float: left;
    position: relative;
}
.tz_author_avata {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 3px solid #f56048;
    overflow: hidden;
    width: 185px;
    height: 185px;
}
.tz-blogSingle .author .author-box .author-avata .tz_author_avata span.tz_author_avata_img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 5px solid #ffffff;
    overflow: hidden;
    display: block;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    position: absolute;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span.TzSocialLink-fa-facebook {
    background: #4396e6;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span.TzSocialLink-fa-twitter {
    background: #15c0cc;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span.TzSocialLink-fa-instagram {
    background: #4c5866;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span.TzSocialLink-fa-google-plus {
    background: #f56048;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span.TzSocialLink-fa-linkedin {
    background: #e45914;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a span i.fa {
    font-size: 14px;
    color: #ffffff;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a.tz_blog_meetup_social_even_0 {
    bottom: -40px;
    left: 32%;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a.tz_blog_meetup_social_even_1 {
    bottom: -40px;
    right: 32%;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a.tz_blog_meetup_social_even_2 {
    bottom: -30px;
    left: 13%;
}
.tz-blogSingle .author .author-box .author-avata .tz-author-social a.tz_blog_meetup_social_even_3 {
    bottom: -30px;
    right: 13%;
}
.tz-blogSingle .author .author-box .author-info {
    margin: 0 0 0 225px;
}
.tz-blogSingle .author .author-box .author-info h3 {
    margin: 0;
    padding: 0;
}
.tz-blogSingle .author .author-box .author-info h3 a {
    font-family: 'Crete Round', serif;
    font-size: 24px;
    font-weight: 400;
    color: #3a424a;
    display: inline-block;
    padding: 13px 0 6px;
    text-decoration: none;
}
.tz-blogSingle .author .author-box .author-info h3 span.tz_author_meetup {
    font-family: 'Lato', sans-serif;
    color: #e45914;
    font-size: 11px;
    text-transform: uppercase;
}
.tz-blogSingle .author .author-box .author-info p {
    color: #646569;
    margin: 5px 0 0;
    line-height: 1.7em;
}
.tz-blogSingle .author .author-box:after {
    content: '';
    display: block;
    clear: both;
}
.tz-blogSingle .author .author-box:hover .author-info h3 a:after {
    width: 100%;
}
.tz-blogSingle .tz_meetup_related_posts h4 {
    font-weight: 600;
    color: #3a424a;
    font-size: 30px;
    margin-bottom: 31px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item {
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-bottom: 30px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:after {
    display: table;
    clear: both;
    content: '';
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item {
    width: 50%;
    float: left;
    height: 250px;
    position: relative;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item img {
    position: relative;
    max-width: none;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blog-icon-item-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blog-icon-item-img a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    line-height: 30px;
    background: #ffffff;
    color: #3a424a;
    display: inline-block;
    margin: 0 2px;
    cursor: pointer;
    font-size: 13px;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    opacity: 0;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blog-icon-item-img a .fa-link {
    padding-left: 2px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blog-icon-item-img a:hover {
    background: #e45914;
    color: #ffffff;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-img:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content {
    background: #f8f8f9;
    padding: 15px 15px 15px 18px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content:after {
    border-bottom: 35px solid #f8f8f8;
    border-left: 20px solid transparent;
    border-top: 10px solid transparent;
    content: "";
    left: -12px;
    position: absolute;
    top: -5px;
    z-index: 1;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3em;
    margin-bottom: 5px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content h4 a {
    color: #3a424a;
    text-decoration: none;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content span.entry-blog-meta {
    color: #cccccc;
    font-family: 'Lato', sans-serif;
    display: inline-block;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content span.entry-blog-meta small.tzinfomation_time {
    font-size: 13px;
    display: inline-block;
    margin-right: 2px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content span.entry-blog-meta small.tzinfomation_time.tzinfomation_time_last {
    margin-right: 0;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content span.entry-blog-meta a {
    text-decoration: none;
    color: #cccccc;
    font-style: italic;
    display: inline-block;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content p {
    line-height: 1.6em;
    color: #818d9a;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content small.tz_meetup_single_comment {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    display: inline-block;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content small.tz_meetup_single_comment a {
    color: #9fadbd;
    text-decoration: none;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item-content small.tz_meetup_single_comment i.fa {
    margin-left: 2px;
    font-size: 11px;
    margin-top: 1px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-icon-item-img a {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-img:after {
    background: rgba(0, 0, 0, 0.5);
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content {
    background: #e45914;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content:after {
    border-bottom-color: #e45914;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content h4 a {
    color: #ffffff;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content span.entry-blog-meta {
    color: #e9e4e4;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content span.entry-blog-meta a {
    color: #e9e4e4;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content p {
    color: #f4d4cf;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item:hover .tz-blog-thubnail-item-content small.tz_meetup_single_comment {
    color: #e9e4e4;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related img {
    position: static;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related .flex-control-nav {
    bottom: 0;
    z-index: 2;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related .flex-control-paging li {
    margin: 0 2px;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related .flex-control-paging li a {
    background: #ffffff;
    box-shadow: none;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related .flex-control-paging li a:hover {
    background: #4396e6;
}
.tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item .tz-blogSlider-related .flex-control-paging li a.flex-active {
    background: #4396e6;
}
.tzComments {
    position: relative;
}
.tzComments:after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #dce2ed;
    bottom: 0;
    left: 0;
    content: '';
}
.tzComments .comments-area .comment-respond {
    padding: 0 0 62px;
}
.tzComments .comments-area .tz-Comment {
    margin: 0 0 77px 0;
}
.tzComments .comments-area .tz-Comment span.TzCommentTitle {
    display: inline-block;
    font-weight: 600;
    font-size: 30px;
    color: #3a424a;
    padding: 0 0 16px;
    margin-bottom: 43px;
}
.tzComments .comments-area .tz-Comment ol.commentlist {
    margin: 0;
    padding: 0;
}
.tzComments .comments-area .tz-Comment ol.commentlist li {
    list-style: none;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article {
    background: #f8f8f8;
    margin-bottom: 26px;
    padding: 42px 20px 39px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.02);
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .comment-author {
    border: 4px solid #ffffff;
    background: #ffffff;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .comment-author img {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .tz_comment_single_meetup cite {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    color: #3a424a;
    display: block;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .tz_comment_single_meetup a.comments-datetime {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    color: #95979d;
    display: block;
    margin-top: -3px;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .tz-commentInfo {
    display: inline-block;
}
.tzComments .comments-area .tz-Comment ol.commentlist li article .tz-commentInfo a {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: #ffffff;
    text-decoration: none;
    padding: 2px 15px;
    background: #88939f;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-transform: uppercase;
    display: inline-block;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tzComments .comments-area .comment-respond form.comment-form .form-comment-item {
    position: relative;
    margin-bottom: 20px;
}
.tzComments .comments-area .comment-respond form.comment-form .form-comment-item input.form-control {
    background: #f8f8f9;
    border: none;
    box-shadow: none;
    height: auto;
    padding: 12px;
    line-height: 1.5em;
    font-family: 'Lato', sans-serif;
    font-style: italic;
    color: #95979d;
}
.tzComments .comments-area .comment-respond form.comment-form .form-comment-item textarea {
    background: #f8f8f9;
    border: none;
    box-shadow: none;
    height: auto;
    padding: 15px;
    line-height: 1.5em;
    font-family: 'Lato', sans-serif;
    font-style: italic;
    color: #95979d;
}
.tzComments .comments-area .comment-respond form.comment-form .form-comment-item i.fa {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 18px;
    color: #95979d;
    margin-top: -7px;
}
.tzComments .comments-area .comment-respond form.comment-form .form-comment-item i.fa.fa-comment {
    top: 15px;
    margin-top: 0;
}
.tzComments .comments-area .comment-respond form.comment-form p.form-submit input.submit {
    font-weight: 700;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-size: 11px;
    padding: 13px 20px;
    color: #ffffff;
    background: #f57560;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.tzComments .comments-area .tz-Comment {
    margin: 0 0 77px 0;
}
.tz-blogSingle .tzpost-pagenavi {
    position: relative;
    margin: 30px 0 0;
}
.tz-blogSingle .tzpost-pagenavi a {
    display: inline-block;
    color: #22262b;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
.tz-blogSingle .tzpost-pagenavi a i {
    font-size: 10px;
    padding: 8px 0;
}
.tz-blogSingle .tzpost-pagenavi a span {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    font-weight: 600;
    margin: 0 6px;
    text-transform: uppercase;
}
.tz-blogSingle .tzpost-pagenavi a[rel="prev"] {
    float: left;
}
.tz-blogSingle .tzpost-pagenavi a[rel="next"] {
    float: right;
}
.tz-blogSingle .tzpost-pagenavi:after {
    content: '';
    display: block;
    clear: both;
}
.tz_SingleContentBox_general .tz-blogSlider ol.flex-control-nav {
    bottom: 0;
    text-align: right;
}
.tz_SingleContentBox_general .tz-blogSlider ol.flex-control-nav li {
    margin: 0 3px;
}
.tz_SingleContentBox_general .tz-blogSlider ol.flex-control-nav li:last-child {
    margin-right: 20px;
}
.tz_SingleContentBox_general .tz-blogSlider ol.flex-control-nav li a {
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: none;
    height: 12px;
    width: 12px;
    text-indent: 100em;
}
.tz_SingleContentBox_general .tz-blogSlider ol.flex-control-nav li a.flex-active {
    background: #e45914;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-blogVideo,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-BlogAudio,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-blogSlider,
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-BlogImage {
    position: relative;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz-blogVideo iframe.iframe-full {
    border: none;
}



/* 6.0 - Sidebar */
.tz-sidebar aside.widget {
    margin-bottom: 35px;
}
.tz-sidebar aside.widget ul {
    margin: 0;
    padding: 0;
}
.tz-sidebar aside.widget ul li {
    font-weight: 500;
    color: #242732;
    list-style: none;
}
.tz-sidebar aside.widget ul li a {
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #dddddd;
    color: #242732;
    list-style: none;
    text-transform: capitalize;
    padding: 6px 0 5px;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
}
.tz-sidebar aside.widget ul li:hover a {
    text-decoration: none;
    color: #e45914;
}
.tz-sidebar aside.widget.widget_categories ul li {
    border-bottom: 1px solid #dddddd;
}
.tz-sidebar aside.widget.widget_categories ul li a {
    display: inline-block;
    border-bottom: none;
}
.tz-sidebar aside.widget select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    background: url("images/bg_down.png") no-repeat scroll right 20px top 19px rgba(0, 0, 0, 0);
    border: 1px solid #dddddd;
    font-family: 'Raleway', serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #797979;
    width: 100%;
    padding: 10px 15px;
}
.tz-sidebar aside.widget.widget_categories ul li ul.children li {
    font-weight: 400;
    border-bottom: none;
}
.tz-sidebar aside.widget.widget_categories ul li ul.children li a {
    color: #646569;
    border-bottom: none;
    padding-bottom: 0;
}
.tz-sidebar aside.widget.widget_categories ul li ul.children li:last-child a {
    padding-bottom: 5px;
}
.tz-sidebar aside.widget.widget_archive ul li {
    font-weight: 500;
    border-bottom: 1px solid #dddddd;
}
.tz-sidebar aside.widget.widget_archive ul li a {
    color: #646569;
    display: inline-block;
    border: none;
}
.tz-sidebar aside.widget.widget_archive ul li:hover a {
    color: #e45914;
}
.tz-sidebar aside.widget:hover .module-title:after {
    width: 100%;
}
.tz-sidebar .footerattr img {
    max-width: 100%;
    height: auto;
}
aside.widget form.searchform {
    position: relative;
}
aside.widget form.searchform .tziconsearch,
aside.widget form.searchform .tzsearchlabel,
aside.widget form.searchform input.Tzsearchsubmit {
    display: none;
}
aside.widget form.searchform input.Tzsearchform {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #dae0ea;
    line-height: 30px;
    padding: 15px 50px 15px 19px;
    font-style: italic;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: #111111;
}
aside.widget form.searchform .tz-icon-form-search {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    color: #bec3cc;
    margin-top: -6px;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
aside.widget.tzsocial a.tzSocial-no {
    color: #ffffff;
    line-height: 29px;
    width: 29px;
    height: 29px;
    text-align: center;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    background: #e45914;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 5px;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-1 {
    background: #3c5a96;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-2 {
    background: #3c5a96;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-3 {
    background: #1dadea;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-4 {
    background: #db4c3e;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-5 {
    background: #517fa4;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-6 {
    background: #c9222d;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-7 {
    background: #1fb0ed;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-8 {
    background: #b90510;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-9 {
    background: #e47334;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-10 {
    background: #0073ff;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-11 {
    background: #0eb9c5;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-12 {
    background: #127cb4;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-13 {
    background: #33506c;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-14 {
    background: #2090d9;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-15 {
    background: #21a114;
}
aside.widget.tzsocial a.tzSocial-no.tzSocial-no-bk-16 {
    background: #5c5c64;
}
aside.widget .tagcloud a {
    font-family: 'Lato', sans-serif;
    color: #c5c6c8;
    background: #50525b;
    padding: 5px 12px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-size: 14px !important;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 2px;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
}
aside.widget .tagcloud a:hover {
    background: #e45914;
    color: #ffffff;
}
aside.widget ul.pretty_flickr:after {
    display: table;
    clear: both;
    content: '';
}
aside.widget ul.pretty_flickr li {
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
}
aside.widget ul.pretty_flickr li {
    border: none;
}
aside.widget ul.pretty_flickr li:hover {
    background: none;
    text-indent: 0;
}
aside.widget .tz-flickr a {
    display: block;
    position: relative;
    border-bottom: none !important;
    padding: 0 !important;
}
aside.widget .tz-flickr a:before {
    content: "\f002";
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -14px;
    z-index: 1;
    font-size: 11px;
    width: 28px;
    height: 28px;
    color: #ffffff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: rgba(36, 39, 50, 0.6);
    padding: 2px 9px;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
}
aside.widget .tz-flickr a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(228, 89, 20, 0.9);
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
}
aside.widget .tz-flickr a:hover:before {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
aside.widget .tz-flickr a:hover:after {
    opacity: 1;
}
aside.widget .tz-flickr a img {
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    float: none;
    margin: 0;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item {
    padding: 0 0 10px;
    margin: 0 0 14px;
    border-bottom: 1px solid #dce2ed;
    position: relative;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item:after {
    position: absolute;
    content: "\f099";
    font-family: 'FontAwesome';
    font-size: 20px;
    background: #e45914;
    color: #ffffff;
    top: 3px;
    left: 0;
    width: 27px;
    height: 27px;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    line-height: 1.3em;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item .tweet-content {
    font-family: 'Raleway', sans-serif;
    color: #646569;
    margin-left: 40px;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item .tweet-content a {
    color: #e45914;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item .tweet-content a:hover {
    text-decoration: none;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .tweet-item .tweet-content span {
    display: block;
    margin: 5px 0 5px;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .twitter-user a.user {
    padding-top: 20px;
    display: block;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: #444444;
}
.tz-sidebar aside.dw_twitter .dw-twitter-inner .twitter-user a.user:hover {
    text-decoration: none;
}

/* 7.0 - Contact */
.tz_gmaps iframe {
    border: medium;
    display: block;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}
.tz_contact_icon_form {
    padding: 119px 0 95px;
}
.tz_contact_meet_2 {
    margin-bottom: 27px;
}
.tz_contact_detail_meet:after {
    display: table;
    clear: both;
    content: '';
}
.tz_contact_detail_meet span.tz_contact_meet_icon {
    background: #3b86cf;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: inline-block;
    float: left;
    text-align: center;
}
.tz_contact_detail_meet span.tz_contact_meet_icon i.fa {
    color: #ffffff;
    font-size: 24px;
    line-height: 48px;
}
.tz_contact_detail_meet h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    margin-left: 65px;
    letter-spacing: 2px;
}
.tz_meetup_contact_detail {
    padding-left: 65px;
    margin-top: -18px;
}
.tz_meetup_contact_detail p {
    margin-bottom: 5px;
}
.tz_meetup_contact_detail p:last-child {
    margin: 0;
}
.tz_contact_detail_meet span.tz_contact_meet_icon.tz_contact_meet_icon_bk_1 {
    background: #c22d81;
}
.tz_contact_detail_meet span.tz_contact_meet_icon.tz_contact_meet_icon_bk_2 {
    background: #e45914;
}
.tz_contact_detail_meet span.tz_contact_meet_icon.tz_contact_meet_icon_bk_3 {
    background: #24aab3;
}
.wpcf7-form .tz_meetup_wpcf7-form p {
    display: block;
    position: relative;
    margin-bottom: 20px;
}
.wpcf7-form .tz_meetup_wpcf7-form p i.fa {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #ccd1db;
    font-size: 20px;
    margin-top: -10px;
}
.wpcf7-form .tz_meetup_wpcf7-form p span {
    display: block;
}
.wpcf7-form .tz_meetup_wpcf7-form p span input {
    width: 100%;
    padding: 15px 10px;
    color: #95979d;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    border: none;
    background: #f8f8f8;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.wpcf7-form .tz_meetup_wpcf7-form p span input:focus {
    border: 1px solid #e45914;
    background: transparent;
}
.wpcf7-form .tz_meetup_wpcf7-form p span textarea.wpcf7-textarea {
    width: 100%;
    padding: 15px 10px;
    color: #95979d;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    border: none;
    background: #f8f8f8;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    height: 185px;
}
.wpcf7-form .tz_meetup_wpcf7-form p span textarea.wpcf7-textarea:focus {
    border: 1px solid #e45914;
    outline: -webkit-focus-ring-color auto 0;
    background: transparent;
}
.wpcf7-form .tz_meetup_wpcf7-form p input.wpcf7-submit {
    text-transform: uppercase;
    background: #e66423;
    border: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 12px;
    width: 100%;
    padding: 16px 10px;
    letter-spacing: 2px;
}
.wpcf7-form .tz_meetup_wpcf7-form p.tz_meetup_contact_textarea i.fa {
    top: 20px;
}
.contact_client {
    padding: 128px 0 80px;
    background: url("images/bk-images-31.jpg");
}

/* * 8.0 - typography */
#tz_typography {
    padding: 145px 0 100px;
}
span.tz_custom_bk_text {
    background-color: #ff9900;
}
span.tz_custom_bk_text_2 {
    background-color: #00ff00;
}
span.tz_custom_bk_text_3 {
    background-color: #ffcc99;
}
span.tz_custom_bk_text_4 {
    background-color: #15c0cc;
}
span.tz_custom_color_text {
    color: #000000;
}

/* 9.0 - Footer */
.tz-footer.tz-footer-type1 {
    background-color: #242732;
    padding: 65px 0 5px;
    text-align: center;
    position: relative;
}
.tz-footer.tz-footer-type1 .tz-backtotop {
    width: 50px;
    height: 50px;
    content: "";
    right: 390px;
    margin-top: -25px;
    position: absolute;
    top: 0;
    z-index: 999;
}
.tz-footer.tz-footer-type1 .tz-backtotop img {
    width: 100%;
    height: auto;
}
.tz-footer.tz-footer-type1 .tz-backtotop:hover {
    cursor: pointer;
}
aside.widget h3.module-title {
    position: relative;
    margin-bottom: 30px;
}
aside.widget h3.module-title span {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 20px;
}
aside.widget h3.module-title:before {
    content: '';
    height: 2px;
    width: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #e45914;
}
.tz-footer.tz-footer-type1 aside.widget h3.module-title:before {
    display: none;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget h3 span {
    font-weight: 300;
    font-size: 36px;
    text-transform: none;
    color: #44495c;
    line-height: 1.2em;
    padding: 0 0 37px;
}
.multicolor-subscribe-form {
    position: relative;
}
.tz-footer.tz-footer-type1 aside {
    margin: 0 auto 15px;
    padding: 0 0 23px;
    box-shadow: 0 1px 0 0 #282828;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe {
    position: relative;
    margin-bottom: 40px;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 33.2%;
    top: 50%;
    left: 0;
    background: #e45914;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 33.2%;
    top: 50%;
    right: 0;
    background: #e45914;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe .max-email-box {
    width: 30%;
    margin: 0 auto;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe form.multicolor-subscribe-form input.email {
    background: #313543;
    border: 2px solid #1e212a !important;
    border-radius: 30px;
    padding: 20px 25px;
    color: #5e6479;
    font-size: 15px;
    font-style: inherit;
}
.tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe form.multicolor-subscribe-form input.mcolor-button {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    top: 10px;
    right: 5px;
    bottom: 10px;
    padding: 5px 40px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    width: auto;
    margin: 0;
    position: absolute;
    background: #e45914 none repeat scroll 0 0;
    border: 1px solid #e45914;
    color: #ffffff;
}
.multicolor-subscribe-form .mcolor-subbox {
    width: 100%;
}
.tz-footer.tz-footer-type1 aside.tzsocial {
    padding: 0;
}
.tz-footer.tz-footer-type1 aside.tzsocial a.tzSocial-no {
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    background: transparent;
    width: auto;
    height: auto;
}
.tz-footer.tz-footer-type1 aside.tzsocial a.tzSocial-no i {
    font-size: 20px;
    color: #495266;
}
.tz-footer.tz-footer-type1 aside.tzsocial a i:hover {
    color: #e45914;
}
.tz-footer.tz-footer-type1 aside.tzsocial a.tzSocial-yes i {
    color: #e45914;
}
.tz-footer.tz-footer-type1 aside.tzsocial .tzSocial_bg_meetup {
    display: inline-block;
    position: relative;
}
.tz-footer.tz-footer-type1 aside.tzsocial .tzSocial_bg_meetup .meetup_line_left {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 25px;
    height: 1px;
    background: #4c5266;
}
.tz-footer.tz-footer-type1 aside.tzsocial .tzSocial_bg_meetup .meetup_line_right {
    position: absolute;
    top: 50%;
    right: 100%;
    width: 25px;
    height: 1px;
    background: #4c5266;
}
.tz-footer.tz-footer-type1 .tzcopyright p {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #656a7e;
    text-transform: uppercase;
    margin: 0 0 3px;
}
.tz-footer.tz-footer-type1 .tzcopyright p a {
    color: #787e92;
}

/* 10.0 - Responsive */

@media (max-width: 1440px) {

    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_social {
        padding: 0 12px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text p {
        margin-bottom: 12px;
        font-size: 13px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text h4 {
        margin-bottom: 10px;
        font-size: 50px;
    }
    .tz_meetup_countdown .tz_meetup_countdown_time span {
        font-size: 40px;
    }
    .tz_home_slider_meetup .tz_slider_meetup_btn a.tz_slider_meetup_btn_1 {
        padding: 8px 25px;
    }
    .tz_font_style_slider_revo_2, .tz_font_style_slider_revo_6 {
        left: -22px;
    }
    .tz_btn_revolution {
        left: -30px;
    }
    .tz_btn_revolution_2 {
        right: -30px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup a {
        font-size: 15px;
        margin: 0 7px;
    }
    .tz_home_slider_meetup .tz_slider_meetup_btn a.tz_slider_meetup_btn_1 {
        padding: 8px 25px;
    }

}

@media (max-width: 1365px) {

    .tz_meetup_slider_blog_single .tz_partner_blog_single_next,
    .tz_meetup_slider_blog_single .tz_partner_blog_single_prev,
    .tz_recent_blog_meetup button {
        display: none;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_social .meetup_line_left,
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_social .meetup_line_right {
        width: 15px;
    }
}

@media (max-width: 1200px) {

    #tz_text_box_button h3 {
        font-size: 18px;
        line-height: 1.5em;
    }
    .tz_register_meetup {
        width: 470px;
    }
    .tz_register_meetup_pricing {
        margin-left: 15px;
    }
    .tz_text_box_slider_padding {
        padding: 75px 0 50px 20px;
    }
    .tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 .tz_title_line_left,
    .tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 .tz_title_line_right {
        display: none;
    }
    .parallax {
        background-attachment: inherit !important;
        background-position: 50% 0 !important;
    }
    .tz-footer.tz-footer-type1 .tz-backtotop {
        right: 90px;
    }

}

@media (max-width: 1199px) and (min-width: 992px) {

    .tz-headerHome nav.tz-menu ul li a {
        font-size: 12px;
        padding: 30px 0;
    }
    .tzHeaderContainer h3 {
        padding: 25px 0 35px;
    }

}
@media (max-width: 991px) {

    .tz-headerHome {
        background: none repeat scroll 0 0 rgba(0,0,0,.7);
        position: relative;
        padding: 25px 0;
    }
    .container {
        width: 100%;

    }
    .tzHeaderContainer h3 {
        padding: 0;
    }
    .tz-headerHome ul li a:before {
        display: none;
    }
    .tz-headerHome .navbar-toggle {
        border-radius: 0;
        color: #fff;
        margin: 0;
        padding: 2px 10px;
        font-size: 21px;
        background: #e45914;
        display: block;
    }
    .navbar-collapse.collapse {
        display: none!important;
        height: auto!important;
        padding-bottom: 0;
        overflow: visible!important;
    }
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    ul.tz-nav li {
        float: none;
        width: auto;
        display: block;
    }
    .tz-headerHome nav.tz-menu {
        width: 100%;
        float: none !important;
        position: absolute;
        top: 100%;
        right: 0;
        background: #eeeeee;
    }
    .tz-headerHome nav.tz-menu .tz-nav {
        width: 100%;
    }
    .tzHeaderContainer ul {
        margin: 0;
    }
    .tz-headerHome nav > ul > li {
        padding: 0;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .tz-headerHome nav.tz-menu ul li a {
        margin: 0;
        padding: 15px 20px;
        border-left: 3px solid #eee;
        color: #454545;
        border-right: none;
        border-bottom: 1px solid #e1dede;
    }
    .tz-headerHome.tz-homeType2 {
        padding: 0;
    }
    .tz-homeType2 .tzHeaderContainer {
        padding: 25px 0;
    }
    .tz-headerHome.tz-homeType2 button.tz-search {
        position: absolute;
        top: 50%;
        margin: -15px 0 0;
        right: 80px;
        width: auto;
        background: #e45914;
        color: #fff;
        padding: 3px 12px;
    }
    .tz-homeType2.tz-homeTypeRelative button.tz-search i.fa {
        color: #fff;
    }
    .tz-headerHome.tz-homeType2 .tz-headerRight {
        text-align: inherit;
    }
    .tz-headerHome.tz-homeType2 .tz-headerRight ul li a {
        padding: 0 4px;
    }
    .tz-headerHome.tz-homeType2 .tz-headerRight ul li a i {
        font-size: 13px;
    }
    .tz-headerHome nav.tz-menu li a:hover,
    .tz-headerHome nav > ul > li:hover,
    .tz-homeType2.tz-homeTypeRelative nav ul.tz-nav > li:hover > a,
    .tz-homeType1.tz-homeTypeFixed nav ul.tz-nav > li:hover > a {
        color: #e45914;
        background: #262939;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .tz-homeType2.tz-homeTypeRelative nav ul.tz-nav > li:hover > a,
    .tz-homeType1.tz-homeTypeFixed nav ul.tz-nav > li:hover > a {
        border-color: #e45914;
    }
    .tz-headerHome nav.tz-menu li.menu-item-has-children:after {
        content: "\f105";
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: 14px;
        font-family: FontAwesome;
        color: #454545;
    }
    .tz-headerHome nav.tz-menu li.menu-item-has-children:hover:after {
        color: #e45914;
        content: "\f107";
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu > li:first-child {
        border-bottom: none;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu {
        margin: 0;
        padding: 0;
        border: none;
        left: 0;
        opacity: 0;
        position: relative;
        top: 0;
        visibility: hidden;
        width: 100%;
        z-index: 50;
        height: 0;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    .tz-headerHome nav > ul > li:hover ul.non_mega_menu,
    .tz-headerHome nav > ul > li a:hover ul.non_mega_menu {
        height: auto;
        top: 0;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu li {
        padding: 0;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu li a {
        background: #dad9d9;
        padding: 15px 20px;
        color: #777;
    }
    .tz-headerHome nav > ul > li > ul.non_mega_menu li a:hover {
        background: #fafafa;
        color: #000000;
    }
    .tz-sectionBreadcrumb {
        padding: 25px 0;
    }
    .tz-sectionBreadcrumb .tz-breadcrumb {
        text-align: left;
    }
    .bx-wrapper .bx-controls-auto, .bx-wrapper .bx-pager {
        bottom: 10px;
    }
    a.tz_btn_video_meetup {
        margin-top: 25px!important;
        margin-left: 0;
        padding: 12px 25px;
    }
    .tz_bk_video_meetup,
    .tz_text_box_slider_padding,
    .tz_bk_box_text_meetup,
    .tz_padding_register,
    .tz_form_register {
        padding: 45px 15px;
    }
    .tzTwitter-slider-box,
    #faq,
    #recent_post {
        padding: 35px 0 50px;
    }
    .tzTwitter-slider .tzTwitter-item span.tweet_text {
        padding: 0 50px;
    }
    .tz_register_meetup {
        width: 100%;
    }
    .tz_register_meetup_pricing {
        margin: 0;
        padding-right: 15px;
    }
    .tz_maniva_meetup_title {
        padding-right: 20px;
    }
    .tz_text_box_slider {
        margin: 0;
        width: 100%;
    }
    .tz_map_meetup {
        height: 350px !important;
    }
    .tz_video_meetup {
        height: auto!important;
        position: static;
    }
    .tz_video_meetup img {
        width: 100%!important;
        height: auto!important;
        position: static;
    }
    .tz_custom_width.tz_custom_width_box_2 {
        width: 90% !important;
    }
    .tz_width_box {
        width: 80%;
    }
    #tz_text_box_button {
        text-align: center;
    }
    .tz_meetup_btn_custom {
        margin-top: 20px;
    }
    .tz_custom_width {
        width: auto !important;
    }
    .tz-blogDefault {
        padding-top: 50px;
    }
    .tz-blogDefault .wp-pagenavi {
        margin: 35px 0;
    }

}

@media (max-width: 768px) {

    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_social a {
        padding: 0;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text {
        margin-bottom: 15px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text p {
        font-size: 11px;
        padding: 0 5px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup .tz_meetup_slider_home_text h4 {
        font-size: 25px;
    }
    .tz_meetup_countdown .tz_meetup_countdown_time {
        margin: 0 10px;
    }
    .tz_meetup_countdown .tz_meetup_countdown_time:after {
        right: -10px;
    }
    .tz_meetup_countdown .tz_meetup_countdown_time span {
        font-size: 15px;
    }
    .tz_meetup_countdown .tz_meetup_countdown_time b {
        font-size: 12px;
    }
    .tz_home_slider_meetup .tz_content_slider_meetup a {
        font-size: 14px;
        padding: 0 8px;
    }
    .tz_image_title_meetup {
        margin-bottom: 15px;
    }
    .tz_custom_width,
    .tz_custom_width.tz_custom_width_box_2 {
        width: auto !important;
    }
    .tz_maniva_meetup_title.tz_maniva_meetup_title_type3 h3 {
        font-size: 15px;
        padding: 0 20px;
    }
    .tz_meetup_our_team_thumbnail {
        height: auto!important;
    }
    .tz_meetup_our_team_thumbnail img {
        position: static!important;
        width: 100%!important;
        height: auto!important;
    }
    .tzTwitter-slider .tzTwitter-item span.tweet_text {
        padding: 0!important;
        font-size: 20px!important;
    }
    .vc_empty_space_4 {
        height: 10px;
    }
    .tz_meetup_ask_question {
        margin: 30px 0;
    }
    .tz_register_meetup_pricing {
        margin-right: 0;
        margin-left: 0;
    }
    .tz_register_meetup_pricing_item_container {
        padding: 15px 5px 15px 10px;
    }
    .tz_register_meetup_pricing_item_container h3 {
        font-size: 15px;
    }
    .tz_register_meetup_pricing_item_container p {
        font-size: 12px;
    }
    .tz_register_meetup_pricing_item_price h3 {
        font-size: 25px;
    }
    .tz_form_register_box.tz_custom_width {
        width: auto !important;
    }
    .custom_col_pading_left {
        padding-left: 15px;
    }
    .custom_col_pading_right {
        padding-right: 15px;
    }
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe .max-email-box {
        width: auto;
    }
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget h3 span {
        font-size: 30px;
    }
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe form.multicolor-subscribe-form input.email {
        margin: 0;
    }
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe form.multicolor-subscribe-form input.mcolor-button {
        padding: 0 30px;
        font-size: 10px;
    }
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe:before,
    .tz-footer.tz-footer-type1 aside.widget.MultiColorSubscribeWidget .multicolor-subscribe:after {
        display: none;
    }
    #about_meetup .custom_col_pading {
        padding-left: 15px;
        padding-right: 15px;
    }
    .tz_tab_custom .nav-tabs:after,
    .tz_tabs_meetup:before,
    .tz_tabs_meetup:after {
        display: none;
    }
    .tz-sidebar aside.widget {
        padding: 0 15px;
    }
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_author {
        display: none;
    }
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox h4.tzSingleBlog_title {
        font-size: 20px;
    }

}

@media (max-width: 480px) {

    .tz_meetup_countdown {
        margin-top: 0;
        margin-bottom: 16px;
    }
    .tz_maniva_meetup_title h3 {
        font-size: 20px;
    }
    .tz_maniva_meetup_title {
        padding-right: 0;
    }
    .tz_maniva_meetup_title.tz_meetup_video_text,
    .tz_box_text_bk {
        width: 100%;
        text-align: center;
    }
    .tz_meetup_video_text .tz_meetup_content {
        margin-top: 0;
    }
    .tz_meetup_content p {
        font-size: 14px;
    }
    a.tz_btn_video_meetup {
        letter-spacing: 1px;
    }
    .tz-counter.tzCounter_type {
        margin-bottom: 15px;
    }
    .tzCounter_type .tz-counter-number em.stat-count {
        font-size: 40px;
    }
    #tz_text_box_button {
        padding-bottom: 50px;
    }
    #tz_text_box_button p {
        margin-bottom: 20px;
    }
    #counter {
        padding: 35px 0;
    }
    .tz_custom_images_slider {
        height: auto!important;
    }
    .tz_box_event_meetup h3 {
        font-size: 14px;
        line-height: 1.4em !important;
    }
    .tz_event_meetup_content {
        padding-left: 45px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail .tz_meetup_box_detail_custom {
        padding: 22px 0 10px 10px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail:before {
        left: -56px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail span.tz_meetup_start_time {
        left: -44px;
        top: 20px;
        font-size: 12px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail h4 {
        font-size: 13px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail p {
        margin-bottom: 5px;
    }
    .tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p {
        font-size: 11px;
        line-height: 1.5em;
    }
    .tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p :first-child {
        margin-bottom: 10px;
    }
    .tz_meetup_btn.tz_custom_btn_padding {
        padding: 0 0 0 45px;
    }
    .tz_meetup_btn h4.tz_meetup_title_btn {
        font-size: 18px;
    }
    .tz_meetup_question h4 {
        font-size: 16px;
    }
    .tz_recent_blog_meetup_content {
        padding: 0;
    }
    .tz_meetup_btn_post {
        margin-top: 40px;
    }
    .tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox {
        padding: 15px;
    }
    .tz-blogDefault .tz-blogItem .tz-blogContent .tz-blogBox h4.title {
        font-size: 22px;
    }
    .tz-blogDefault .wp-pagenavi {
        text-align: center;
    }
    .tz-blogDefault .wp-pagenavi a, .tz-blogDefault .wp-pagenavi span {
        font-size: 13px;
        padding: 7px 11px;
    }
    .tz-blogSingle {
        padding: 50px 0;
    }
    .tz-blogSingle .tz-blogSingleContent {
        padding-left: 0;
        margin-bottom: 0;
    }
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox {
        padding: 10px 10px 40px;
    }
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-BlogAudio,
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-BlogImage,
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-blogSlider,
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz-blogVideo {
        margin-bottom: 15px;
        position: relative;
    }
    .tz-blogSingle .tz-blogSingleContent .tz-SingleContentBox .tz_SingleContentBox_general .tz_SingleContentBox_like {
        bottom: -40px;
    }
    .tz-blogSingle .author .author-box {
        padding: 30px 15px 0 15px;
    }
    .tz-blogSingle .author .author-box .author-avata {
        float: none;
        display: inline-block;
    }
    .tz-blogSingle .author .author-box .author-info {
        margin: 30px 0;
    }
    .tz-blogSingle .tz_meetup_related_posts {
        padding: 0 15px;
    }
    .tz-blogSingle .tz_meetup_related_posts h4 {
        font-size: 20px;
    }
    .tz-blogSingle .tz_meetup_related_posts .tz_meetup_related_posts_content .tz-blog-item .tz-blog-thubnail-item {
        width: auto;
        float: none;
    }
    .tzComments .comments-area .tz-Comment ol.commentlist li article .comment-author {
        display: inline-block;
        float: none;
        width: 100%;
    }
    .tzComments .comments-area .tz-Comment ol.commentlist li article .comment-author img {
        height: auto;
        width: 100%;
    }
    .tz_comment_single_meetup {
        margin-right: 10px;
    }
    .tzComments .comments-area .tz-Comment ol.commentlist li article .tz-commentInfo {
        float: none!important;
        margin: 10px 0;
    }

}